import * as amplitude from '@amplitude/analytics-browser';

import { userHasConsentedToMarketing } from '@/features/gdpr/utils/permissions';

let amplitudeLoaded = false;
function startAmplitude() {
  if (
    !amplitudeLoaded &&
    import.meta.env.VITE_AMPLITUDE_API_KEY &&
    userHasConsentedToMarketing()
  ) {
    try {
      amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
        defaultTracking: true,
        minIdLength: 1, // amplitude prefers non-sequential ids and default here is like 5
      });
      amplitudeLoaded = true;
    } catch {
      //pass
    }
  }
}

startAmplitude();

// only initialize Amplitude when Cookiebot is ready
// and if the user has consented to statistics
window.addEventListener('CookiebotOnConsentReady', startAmplitude);
