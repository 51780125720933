import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import {
  configureRecurly,
  createRecurlyChallenge,
  destroyRecurly,
  RecurlyToken,
} from '@/recurly';

interface PaymentChallengeProps {
  token: string;
  onFinish: (token: RecurlyToken) => void;
  onError: (error: any) => void;
}

export function PaymentChallenge(props: PaymentChallengeProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const onFinish = useRef(props.onFinish);
  const onError = useRef(props.onError);

  useEffect(() => {
    const containerElement = containerRef.current;

    if (containerElement == null) {
      throw new Error('Container ref is empty');
    }

    const recurly = configureRecurly({
      publicKey: import.meta.env.VITE_RECURLY_PUBLIC_KEY,
    });

    recurly
      .then((instance) =>
        createRecurlyChallenge(instance, props.token, containerElement)
      )
      .then(onFinish.current, onError.current);

    return () => {
      recurly.then((instance) => {
        destroyRecurly(instance);
      });
    };
  }, [props.token]);

  useEffect(() => {
    onFinish.current = props.onFinish;
    onError.current = props.onError;
  });

  return <PaymentChallengeContainer ref={containerRef} />;
}

const PaymentChallengeContainer = styled.div`
  width: 100%;
  height: 500px;
`;
