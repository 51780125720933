import { useContext } from 'react';

import { GenericPricingUpsellModalContext } from '../components';

export const useGenericPricingUpsellModalContext = () => {
  const context = useContext(GenericPricingUpsellModalContext);

  if (!context) {
    throw new Error(
      'useGenericPricingUpsellModalContext must be used within a GenericPricingUpsellModalContextProvider'
    );
  }
  const { isOpen, openPricingUpsell, closePricingUpsell, type, plan } = context;

  return { isOpen, openPricingUpsell, closePricingUpsell, type, plan };
};
