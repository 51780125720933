import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

import bikeBackward from '../assets/bike-backward.svg';

import { Illustration, Header, Content } from '.';

interface DowngradeFreeProps {
  onContinue: () => void;
  onGoBack: () => void;
}

export const DowngradeFree = ({ onContinue, onGoBack }: DowngradeFreeProps) => {
  return (
    <>
      <Content>
        <Header>
          <h1>Continue on Free</h1>
          <p>
            On the Free plan you can only have <b>one active job opening</b>.
            Your job openings are currently paused and you can choose which one
            to reopen.
          </p>
        </Header>

        <Note>
          <b>Note:</b> on the Free plan you can only access candidate data for
          30 days.
        </Note>

        <Footer>
          <DSButton variant="secondary" onClick={() => onGoBack()}>
            Go back
          </DSButton>
          <DSButton variant="primary-purple" onClick={() => onContinue()}>
            Continue on Free
          </DSButton>
        </Footer>
      </Content>

      <Illustration src={bikeBackward} alt="" centered />
    </>
  );
};

const Note = styled.div`
  padding: 12px;
  background: ${({ theme }) => theme.colors.purple[5]};
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.purple[100]};
  border-radius: 8px;
`;

const Footer = styled.footer`
  display: flex;
  gap: 8px;
  justify-content: right;
  flex-wrap: wrap;
`;
