import React from 'react';
import { DSTestResultPage } from '@hundred5/design-system';
import { useFormikContext } from 'formik';

import { FormikCheckboxField } from '@/features/common';

import { TResultPageSettingsForm } from '../result-page-settings-form.types';

export function Experience() {
  const form = useFormikContext<TResultPageSettingsForm>();

  return (
    <DSTestResultPage.DataSection title="Test experience">
      <FormikCheckboxField
        name="userExperience"
        label="Ask candidates about their test experience"
        onChange={(e) => {
          form.setFieldValue('userExperience', e.target.checked);
        }}
      />
    </DSTestResultPage.DataSection>
  );
}
