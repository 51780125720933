import React from 'react';
import styled from '@emotion/styled';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMedia } from 'react-use';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { Icon } from '@/features/common';
import { TestType, TTestType } from '@/features/test/types';

interface TestTypeProps {
  active?: boolean;
  onClick?: () => void;
  type: TTestType;
}

const types = {
  [TestType.Quiz]: {
    icon: <Icon icon={regular('ruler')} />,
    activeIcon: <Icon icon={solid('ruler')} />,
    title: 'Skills Test',
    description: 'Test core knowledge for a specific job role or skill',
    shortDescription: 'Test essential skills',
    desktopOnly: false,
  },
  [TestType.Video]: {
    icon: <Icon icon={regular('video')} />,
    activeIcon: <Icon icon={solid('video')} />,
    title: 'Async Interview',
    description: 'Assess ability asynchronously with video answers',
    shortDescription: '',
    desktopOnly: true,
  },
  [TestType.Homework]: {
    icon: <Icon icon={regular('file-lines')} />,
    activeIcon: <Icon icon={solid('file-lines')} />,
    title: 'Homework Task',
    description: 'Measure performance on real-world tasks',
    shortDescription: '',
    desktopOnly: true,
  },
  [TestType.Psychometric]: {
    icon: <Icon icon={regular('brain-circuit')} />,
    activeIcon: <Icon icon={solid('brain-circuit')} />,
    title: 'Psychometric Test',
    description: "Gain insight into a candidate's personality",
    shortDescription: '',
    desktopOnly: true,
  },
};

export function TestTypeTab({ active, onClick, type }: TestTypeProps) {
  const amplitude = getAmplitudeClient();
  const tab = types[type];
  const isMobile = useMedia('(max-width: 768px)');

  return (
    <Button
      onClick={() => {
        if (!active) {
          onClick && onClick();
          amplitude?.logEvent('test library/navigate to tab', {
            'test type': tab.title,
          });
        }
      }}
      active={active}
      disabled={isMobile && tab.desktopOnly}
    >
      <Container type={type}>
        <TitleContainer>
          {!isMobile && (active ? tab.activeIcon : tab.icon)}
          {tab.title}
        </TitleContainer>
        <Description>
          {!isMobile
            ? tab.description
            : tab.desktopOnly
              ? 'Desktop only'
              : tab.shortDescription}
        </Description>
      </Container>
    </Button>
  );
}

const Container = styled.div<{ type: TTestType }>`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: ${(props) =>
    props.type === TestType.Quiz
      ? '36px 18px 16px 36px'
      : '36px 18px 16px 18px'};
  cursor: pointer;

  @media all and (max-width: 768px) {
    padding: ${(props) =>
      props.type === TestType.Quiz ? '12px 8px 12px 16px' : '12px 8px'};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  column-gap: 8px;
  align-items: center;
  margin-bottom: 4px;
  text-align: left;
`;

const Description = styled.div`
  text-align: left;
`;

const Button = styled.button<{ active?: boolean; disabled?: boolean }>`
  background-color: ${(props) =>
    props.active ? props.theme.colors.white : props.theme.colors.peach[50]};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  width: 100%;
  display: flex;
  align-items: flex-start;

  border: none;
  color: ${(props) => props.theme.colors.purple[100]};
  overflow: hidden;

  &:hover {
    background-color: ${(props) =>
      !props.active && props.theme.colors.peach[100]};
  }

  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
