import { useCallback } from 'react';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { TId } from '@/features/common';
import { useDeleteQuestionMutation } from '@/features/questions';
import { useHistory } from '@/hooks/router';

export const useDeleteQuestion = (
  workspaceId: TId,
  jobOpeningId: TId,
  testId: TId,
  questionId: TId
) => {
  const amplitude = getAmplitudeClient();
  const history = useHistory();
  const deleteQuestionMutation = useDeleteQuestionMutation({
    testId,
    onSuccess: () =>
      history.push(
        `/admin/${workspaceId}/openings/${jobOpeningId}/test/${testId}/questions?${history.location.search}`
      ),
  });
  const deleteQuestion = useCallback(async () => {
    await deleteQuestionMutation.mutateAsync({
      testId,
      questionId,
    });
    amplitude?.logEvent('question creation/delete question', {
      'workspace id': workspaceId,
      'job opening id': jobOpeningId,
      'test id': testId,
      'question id': questionId,
    });
  }, [
    deleteQuestionMutation,
    testId,
    questionId,
    amplitude,
    workspaceId,
    jobOpeningId,
  ]);

  return { deleteQuestion, isDeleting: deleteQuestionMutation.isLoading };
};
