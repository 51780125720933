import { userHasConsentedToStatistics } from '@/features/gdpr/utils/permissions';

let hubspotLoaded = false;
function loadHubspot() {
  const accountId = import.meta.env.VITE_HUBSPOT_ACCOUNT_ID;
  if (!hubspotLoaded && accountId && userHasConsentedToStatistics()) {
    window._hsq = window._hsq || [];

    const script = document.createElement('script');
    script.src = `https://js.hs-scripts.com/${accountId}.js`;
    script.id = 'hs-script-loader';
    script.async = true;
    script['data-cookieconsent'] = 'statistics';

    document.head.appendChild(script);

    window.dispatchEvent(new Event('hubspot-loaded'));
    hubspotLoaded = true;
  }
}

// try to load hubspot script when the page loads
// If cookiebot is not available we add an event listener
loadHubspot();

// add Hubspot script when Cookiebot is ready
// and if the user has consented to statistics
window.addEventListener('CookiebotOnConsentReady', loadHubspot);
