import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';
import { format } from 'date-fns/format';
import { capitalize } from 'lodash';

import {
  BillingPlan as BillingPlanType,
  IBillingPlansDescription,
} from '@/features/billing';
import { Icon } from '@/features/common';
import {
  getPlanName,
  getPlanPeriod,
  getPlanStrenght,
  isATSPlan,
} from '@/hooks/planLimits';
import { useWorkspaceId } from '@/hooks/router';

interface IBillingPlanProps {
  isTrialVariant?: boolean;
  planDescription: IBillingPlansDescription;
  selectedPeriod: 'monthly' | 'annual';
  currentPlan: BillingPlanType;
  subscribe: () => void;
  cancelSubsctiption: () => void;
  downgradeDate?: Date | null;
}

export const BillingPlan = ({
  isTrialVariant,
  planDescription,
  selectedPeriod,
  currentPlan,
  subscribe,
  cancelSubsctiption,
  downgradeDate,
}: IBillingPlanProps) => {
  const workspaceId = useWorkspaceId();
  const currentPlanName = getPlanName(currentPlan);
  const currentPeriod =
    currentPlanName === 'free' ? selectedPeriod : getPlanPeriod(currentPlan);
  const isOnLegacyPlan = !isATSPlan(currentPlan);

  const isCurrentPlan =
    (currentPlanName === 'free' || !isOnLegacyPlan) &&
    planDescription.plan === currentPlanName &&
    selectedPeriod === currentPeriod;

  const isCurrentPlanLevelButDifferentPeriod =
    !isOnLegacyPlan &&
    planDescription.plan === currentPlanName &&
    selectedPeriod !== currentPeriod;

  const isDowngradingToFree =
    planDescription.plan === 'free' && !!downgradeDate;

  const isOnPaidLegacyPlan = isOnLegacyPlan && planDescription.plan !== 'free';

  const isHigherThanCurrentPlan =
    getPlanStrenght(planDescription.plan) > getPlanStrenght(currentPlanName);

  const isLowerThanCurrentPlan =
    getPlanStrenght(planDescription.plan) < getPlanStrenght(currentPlanName);

  const logButtonClickEvent = (label: string) => {
    amplitude?.logEvent(`billing/"${label}" button click`, {
      'workspace id': workspaceId,
      'selected plan': planDescription.planCode[selectedPeriod],
    });
  };

  return (
    <BillingPlanContainer>
      <Header>
        <h3>{capitalize(planDescription.plan)}</h3>
        <p>{planDescription.description}</p>
      </Header>

      <Price>
        ${planDescription.price[selectedPeriod].perMonth}
        <span> /mo</span>
      </Price>

      <Action>
        {isTrialVariant ? (
          <DSButton
            variant="secondary"
            onClick={() => {
              subscribe();
            }}
          >
            {isHigherThanCurrentPlan
              ? `Upgrade to ${capitalize(planDescription.plan)}`
              : 'Continue on Free'}
          </DSButton>
        ) : (
          <>
            {isCurrentPlan ? (
              <PlanStatus>
                <Icon icon={regular('check')} color="orange-100" />
                Current plan
              </PlanStatus>
            ) : isCurrentPlanLevelButDifferentPeriod ? (
              <DSButton
                onClick={() => {
                  subscribe();
                  logButtonClickEvent('switch period');
                }}
                variant="primary-purple"
              >
                Switch to {capitalize(selectedPeriod)}
              </DSButton>
            ) : isDowngradingToFree ? (
              <PlanStatus fontSize="12px">
                Downgrading to Free on {format(downgradeDate!, 'MMM d, yyyy')}
              </PlanStatus>
            ) : isOnPaidLegacyPlan ? (
              <DSButton
                onClick={() => {
                  subscribe();
                  logButtonClickEvent('change plan');
                }}
                variant="primary-purple"
              >
                Change plan
              </DSButton>
            ) : isHigherThanCurrentPlan ? (
              <DSButton
                onClick={() => {
                  subscribe();
                  logButtonClickEvent('upgrade');
                }}
                variant="primary-purple"
              >
                Upgrade to {capitalize(planDescription.plan)}
              </DSButton>
            ) : isLowerThanCurrentPlan ? (
              <DSButton
                variant="secondary"
                onClick={() => {
                  planDescription.plan === 'free'
                    ? cancelSubsctiption()
                    : subscribe();
                  logButtonClickEvent('downgrade');
                }}
              >
                Downgrade to {capitalize(planDescription.plan)}
              </DSButton>
            ) : null}
          </>
        )}
      </Action>

      <Limits>
        <h2>Overview</h2>
        <ul>
          {planDescription.limits.map((item) => (
            <li key={item.limit}>
              <Icon icon={regular('check')} fontSize="12px" />
              {item.limit}
              {item.info && (
                <Icon
                  icon={regular('circle-info')}
                  fontSize="12px"
                  color="purple-60"
                  data-rh={item.info}
                  data-rh-at="top"
                />
              )}
            </li>
          ))}
        </ul>
      </Limits>
    </BillingPlanContainer>
  );
};

const BillingPlanContainer = styled.div`
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 4;
  min-width: 244px;
  max-width: 342px;
  padding: 36px;
  align-items: stretch;
  gap: 16px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadow.card};

  @container billing-plans-container (width < 956px) {
    padding: 36px 24px;
  }

  @container billing-plans-container (width < 820px) {
    padding: 36px 16px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${(props) => props.theme.typography.colorPrimary};

  h3 {
    font-family: 'GT Haptik';
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }

  p {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
  }
`;

const Price = styled.div`
  margin-bottom: 24px;
  color: ${(props) => props.theme.typography.colorPrimary};
  font-family: 'GT Haptik';
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  span {
    font-size: 24px;
    font-weight: 500;
  }
`;

const Action = styled.div`
  min-height: 36px;

  button {
    width: 100%;
  }
`;

const PlanStatus = styled.div<{ fontSize?: string }>`
  display: flex;
  min-height: 36px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.purple[10]};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  color: ${(props) => props.theme.typography.colorPrimary};
  font-weight: 500;
  text-align: center;
`;

const Limits = styled.div`
  h2 {
    margin: 16px 0;
    color: ${(props) => props.theme.typography.colorPrimary};
    font-family: 'GT Haptik';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 8px;

    li {
      display: flex;
      align-items: center;
      gap: 8px;
      color: ${(props) => props.theme.typography.colorPrimary};
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
    }
  }
`;
