import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { useCandidateSearchParams } from '@/features/candidate-search';
import { TId } from '@/features/common';
import { DASHBOARD_JOB_OPENINGS_QUERY_KEY } from '@/features/dashboard';
import {
  addCandidate,
  cloneJobOpening,
  closeJobOpening,
  createJobOpening,
  deleteJobOpening,
  disableJobOpeningReapply,
  enableJobOpeningReapply,
  fetchJobOpening,
  openJobOpening,
  patchJobOpening,
  pauseJobOpening,
  unpauseJobOpening,
} from '@/features/job-opening';
import { PIPELINE_CANDIDATES_KEY } from '@/features/pipeline';
import {
  WORKSPACE_CANDIDATES_KEY,
  WORKSPACE_CANDIDATES_STATS_QUERY_KEY,
  WORKSPACE_STATS_QUERY_KEY,
} from '@/features/workspace';
import { useJobOpeningId, useWorkspaceId } from '@/hooks/router';
import { Id } from '@/types/misc';

export const JOB_OPENING_QUERY_KEY = ['job-opening'];

export const useJobOpeningQuery = (options?: UseQueryOptions) => {
  const jobOpeningId = useJobOpeningId();

  return useQuery(
    [...JOB_OPENING_QUERY_KEY, jobOpeningId],
    () => fetchJobOpening({ jobOpeningId }),
    options as any
  );
};

export const useJobOpeningByIdQuery = (
  params: { jobOpeningId?: Id },
  options?: UseQueryOptions
) => {
  return useQuery(
    [...JOB_OPENING_QUERY_KEY, params.jobOpeningId],
    () => fetchJobOpening({ jobOpeningId: params.jobOpeningId! }),
    {
      enabled: !!params?.jobOpeningId,
      ...(options as any),
      meta: {
        ...options?.meta,
        skipDefaultErrorHandler:
          options?.meta?.skipDefaultErrorHandler ?? false,
      },
    }
  );
};

export const useUpdateJobOpeningMutation = (
  params: {
    jobOpeningId: TId;
    onSuccess?: () => void;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...rest } = options || {};

  return useMutation(patchJobOpening, {
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess(data) {
      if (params.onSuccess) {
        params.onSuccess();
      }
      const queryKey = [...JOB_OPENING_QUERY_KEY, params.jobOpeningId];
      queryClient.setQueryData(queryKey, data);
    },
    ...(rest as any),
  });
};

export const usePauseJobOpeningMutation = (
  params: {
    jobOpeningId: TId;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(pauseJobOpening, {
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess() {
      queryClient.invalidateQueries([
        ...JOB_OPENING_QUERY_KEY,
        params.jobOpeningId,
      ]);
      queryClient.invalidateQueries(DASHBOARD_JOB_OPENINGS_QUERY_KEY);
    },
    ...(options as any),
  });
};

export const useUnpauseJobOpeningMutation = (
  params: {
    jobOpeningId: TId;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(unpauseJobOpening, {
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess() {
      queryClient.invalidateQueries([
        ...JOB_OPENING_QUERY_KEY,
        params.jobOpeningId,
      ]);
      queryClient.invalidateQueries(DASHBOARD_JOB_OPENINGS_QUERY_KEY);
    },
    ...(options as any),
  });
};

/**
 * Enable job opening reapply
 * @param params - includes job opening id and an optional callback for mutation success
 * @param options - mutation options
 */
export const useEnableJobOpeningReapplyMutation = (
  params: {
    jobOpeningId: TId;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(enableJobOpeningReapply, {
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess() {
      queryClient.invalidateQueries([
        ...JOB_OPENING_QUERY_KEY,
        params.jobOpeningId,
      ]);
    },
    ...(options as any),
  });
};

/**
 * Disable job opening reapply
 * @param params - includes job opening id and an optional callback for mutation success
 * @param options - mutation options
 */
export const useDisableJobOpeningReapplyMutation = (
  params: {
    jobOpeningId: TId;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(disableJobOpeningReapply, {
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess() {
      queryClient.invalidateQueries([
        ...JOB_OPENING_QUERY_KEY,
        params.jobOpeningId,
      ]);
    },
    ...(options as any),
  });
};

// TODO: move to @/features/share
export const useAddCandidateMutation = () => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();
  const { searchParams: candidatesSearchParams } = useCandidateSearchParams();

  return useMutation(addCandidate, {
    onSuccess: (_, params) => {
      void queryClient.invalidateQueries([
        ...WORKSPACE_CANDIDATES_KEY,
        candidatesSearchParams,
        workspaceId,
      ]);
      queryClient.invalidateQueries([
        ...WORKSPACE_STATS_QUERY_KEY,
        workspaceId,
      ]);
      queryClient.invalidateQueries([
        ...WORKSPACE_CANDIDATES_STATS_QUERY_KEY,
        workspaceId,
      ]);
      queryClient.invalidateQueries([
        ...PIPELINE_CANDIDATES_KEY,
        params.jobOpeningId,
        params.attributes.categoryId,
      ]);
    },
  });
};

export const useCreateJobOpeningMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  return useMutation(
    (params: { name?: string }) =>
      createJobOpening({
        workspaceId,
        name: params.name,
      }),
    {
      mutationKey: JOB_OPENING_QUERY_KEY,
      onSuccess() {
        queryClient.invalidateQueries({
          queryKey: [...DASHBOARD_JOB_OPENINGS_QUERY_KEY],
        });

        queryClient.invalidateQueries([
          ...WORKSPACE_STATS_QUERY_KEY,
          workspaceId,
        ]);
      },
      ...(options as any),
    }
  );
};

export const useDeleteJobOpeningMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  return useMutation(deleteJobOpening, {
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess() {
      queryClient.invalidateQueries([...DASHBOARD_JOB_OPENINGS_QUERY_KEY]);
      queryClient.invalidateQueries([...WORKSPACE_CANDIDATES_KEY]);
      queryClient.invalidateQueries([
        ...WORKSPACE_STATS_QUERY_KEY,
        workspaceId,
      ]);
      queryClient.invalidateQueries([
        ...WORKSPACE_CANDIDATES_STATS_QUERY_KEY,
        workspaceId,
      ]);
    },
    ...(options as any),
  });
};

export const useCloneJobOpeningMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  return useMutation(cloneJobOpening, {
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess() {
      queryClient.invalidateQueries([...DASHBOARD_JOB_OPENINGS_QUERY_KEY]);
      queryClient.invalidateQueries([
        ...WORKSPACE_STATS_QUERY_KEY,
        workspaceId,
      ]);
      queryClient.invalidateQueries([
        ...WORKSPACE_CANDIDATES_STATS_QUERY_KEY,
        workspaceId,
      ]);
    },
    ...(options as any),
  });
};

export const useCloseJobOpeningMutation = (
  params: { jobOpeningId: TId },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  return useMutation(closeJobOpening, {
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess() {
      queryClient.invalidateQueries([
        ...JOB_OPENING_QUERY_KEY,
        params.jobOpeningId,
      ]);
      queryClient.invalidateQueries([...DASHBOARD_JOB_OPENINGS_QUERY_KEY]);
      queryClient.invalidateQueries([
        ...WORKSPACE_STATS_QUERY_KEY,
        workspaceId,
      ]);
    },
    ...(options as any),
  });
};

export const useOpenJobOpeningMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  return useMutation(openJobOpening, {
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess(_, variables) {
      queryClient.invalidateQueries([
        ...JOB_OPENING_QUERY_KEY,
        variables.jobOpeningId,
      ]);
      queryClient.invalidateQueries([...DASHBOARD_JOB_OPENINGS_QUERY_KEY]);
      queryClient.invalidateQueries([
        ...WORKSPACE_STATS_QUERY_KEY,
        workspaceId,
      ]);
    },
    ...(options as any),
  });
};
