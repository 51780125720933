export function userHasConsentedToMarketing() {
  return !!window.Cookiebot?.consent?.marketing;
}
export function userHasConsentedToStatistics() {
  return !!window.Cookiebot?.consent?.statistics;
}

export function userHasConsentedToPreferences() {
  return !!window.Cookiebot?.consent?.preferences;
}
