import { useCallback, useEffect, useState } from 'react';

import { IMe, useMeQuery } from '@/features/me';
import { isUserInSupportMode } from '@/features/me/utils/me';
import { useDetectDeadClicks } from '@/hooks/deadClicks';
import { useDetectRageClicks } from '@/hooks/rageClicks';
import { useLocation } from '@/hooks/router';

import {
  bootIntercom,
  shutdownIntercom,
  trackIntercomEvent,
  updateIntercom,
} from '../utils';

const demoEmailPattern = /^demo\+.+@hire\.toggl\.com$/;

const isIntercomEnabled = (me: IMe) => {
  return (
    !demoEmailPattern.test(me.email) && // not a demo user
    !isUserInSupportMode(me) &&
    me.isEmailVerified
  );
};

export function Intercom() {
  const location = useLocation();
  const meQuery = useMeQuery();

  const [isIntercomAvailable, setIsIntercomAvailable] = useState(
    !!window.Intercom
  );
  const handleIntercomLoad = useCallback(() => {
    setIsIntercomAvailable(true);
  }, []);

  useEffect(() => {
    window.addEventListener('intercom-loaded', handleIntercomLoad);
    return () =>
      window.removeEventListener('intercom-loaded', handleIntercomLoad);
  }, [handleIntercomLoad]);

  useDetectRageClicks(
    (target) => {
      trackIntercomEvent('rage_click', {
        target: target,
      });
    },
    {
      interval: 750,
      limit: 3,
    }
  );

  useDetectDeadClicks(
    (target) => {
      trackIntercomEvent('dead_click', {
        target: target,
      });
    },
    {
      interval: 1000,
      limit: 2,
    }
  );

  useEffect(() => {
    if (!isIntercomAvailable) return;

    if (
      meQuery.isSuccess &&
      meQuery.data.intercomUserHash != null &&
      isIntercomEnabled(meQuery.data)
    ) {
      bootIntercom(meQuery.data);
    } else {
      shutdownIntercom();
    }
  }, [isIntercomAvailable, meQuery.data, meQuery.isSuccess]);

  useEffect(() => {
    updateIntercom();
  }, [location.pathname]);

  return null;
}
