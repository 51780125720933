import React from 'react';
import styled from '@emotion/styled';
import { DSModal } from '@hundred5/design-system';

import { BillingPlan } from '@/features/billing';
import { useWorkspaceQuery } from '@/features/workspace';
import { useWorkspaceIdOrNull } from '@/hooks/router';
import { useUpsellModal } from '@/hooks/upsellModal';
import { UpsellModalOptions, UpsellModalType } from '@/types/upsellModal';

import { PremiumUpsellIcon } from './premium-upsell-icon';

export function UpsellModal() {
  const { isOpen, type, options, close } = useUpsellModal();
  const workspaceId = useWorkspaceIdOrNull();
  const workspaceQuery = useWorkspaceQuery({
    workspaceId: workspaceId ?? undefined,
  });

  return (
    <DSModal
      fluid
      open={isOpen}
      onClose={() => close()}
      contentStyle={{ padding: 0 }}
      layer={2}
    >
      <Container>
        <DSModal.CloseButton onClick={() => close()} fixed small />
        <Icon />
        <Title>{getHeader(type)}</Title>
        <Description>
          {getText(type, options, workspaceQuery.data?.subscriptionPlan)}
        </Description>
      </Container>
    </DSModal>
  );
}

const getHeader = (type: UpsellModalType | null): string => {
  switch (type) {
    case 'question_generation_limit':
      return 'Limit reached';

    default:
      return '';
  }
};

const getText = (
  type: UpsellModalType | null,
  options: UpsellModalOptions | null,
  plan: BillingPlan | undefined
): string => {
  switch (type) {
    case 'question_generation_limit': {
      return `You can't add more ${options?.meta?.skillName} questions as you have reached the limit on current plan. To get more questions try changing the question type or difficulty level.`;
    }
    default:
      return '';
  }
};

const Container = styled.div`
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 572px;
`;

const Title = styled.div`
  font-family: GT Haptik;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  margin-bottom: 16px;
`;

const Description = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.purple[60]};
`;

const Icon = styled(PremiumUpsellIcon)`
  margin: 32px;
  height: 128px;
  width: 214px;
`;
