import { decamelizeKeys } from 'humps';

import api from '@/api/api';
import { TId } from '@/features/common';
import {
  IJobOpening,
  TJobOpeningReapplyAfterUnit,
} from '@/features/job-opening';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

export async function fetchJobOpening(params: {
  jobOpeningId: TId;
}): Promise<IJobOpening> {
  const response = await api.get(`/openings/${params.jobOpeningId}`);
  const data = parseAPIData<IJobOpening>(response.data);
  return data;
}

export async function patchJobOpening(params: {
  jobOpeningId: TId;
  settings: Partial<IJobOpening>;
}): Promise<IJobOpening> {
  const response = await api.patch(
    `/openings/${params.jobOpeningId}`,
    decamelizeKeys(params.settings)
  );
  const data = parseAPIData<IJobOpening>(response.data);
  return data;
}

export async function pauseJobOpening(params: {
  jobOpeningId: TId;
  pausedAt: Date;
}): Promise<void> {
  await api.post(`/openings/${params.jobOpeningId}/pause`, {
    paused_at: params.pausedAt,
  });
}

export async function unpauseJobOpening(params: {
  jobOpeningId: TId;
}): Promise<void> {
  await api.post(`/openings/${params.jobOpeningId}/unpause`);
}

interface JobOpeningReapplyParams {
  jobOpeningId: TId;
  reapplyAfter: number;
  reapplyAfterUnit: TJobOpeningReapplyAfterUnit;
}

export async function enableJobOpeningReapply({
  jobOpeningId,
  ...params
}: JobOpeningReapplyParams): Promise<void> {
  await api.patch(`/openings/${jobOpeningId}`, serialiseAPIData(params));
}

export async function disableJobOpeningReapply(params: {
  jobOpeningId: TId;
}): Promise<void> {
  await api.patch(`/openings/${params.jobOpeningId}`, {
    reapply_after: null,
  });
}

export async function createJobOpening(params: {
  workspaceId: TId;
  name?: string;
}): Promise<IJobOpening> {
  const response = await api.post(
    `/workspaces/${params.workspaceId}/openings`,
    serialiseAPIData({ name: params.name })
  );

  return parseAPIData<IJobOpening>(response.data);
}

export async function deleteJobOpening(params: {
  jobOpeningId: TId;
}): Promise<void> {
  await api.delete(`/openings/${params.jobOpeningId}`);
}

export async function cloneJobOpening(params: {
  jobOpeningId: TId;
  name?: string;
}): Promise<IJobOpening> {
  const response = await api.post(
    `/openings/${params.jobOpeningId}/clone`,
    decamelizeKeys({
      name: params.name,
    })
  );

  return parseAPIData<IJobOpening>(response.data);
}

export async function closeJobOpening(params: {
  jobOpeningId: TId;
}): Promise<void> {
  await api.post(`/openings/${params.jobOpeningId}/unpublish`);
}

export async function openJobOpening(params: {
  jobOpeningId: TId;
}): Promise<void> {
  await api.post(`/openings/${params.jobOpeningId}/open`);
}

// TODO: move to @/features/share
export async function addCandidate(params: {
  jobOpeningId: TId;
  attributes: {
    categoryId: TId;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
    city?: string;
    country?: string;
  };
}): Promise<void> {
  await api.post(`/openings/${params.jobOpeningId}/candidate`, {
    ...decamelizeKeys(params.attributes),
    category_id: parseInt(params.attributes.categoryId),
  });
}
