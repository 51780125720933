import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import {
  ICancelModalStepProps,
  useDeleteSubscriptionMutation,
  usePostFeedbackChurnSurvey,
} from '@/features/billing';
import {
  FormikInputField,
  FormikRadioField,
  FormikTextAreaField,
} from '@/features/common';
import { useNotifications } from '@/features/notifications';
import { trackSwitchPlan } from '@/googleTagManager';
import { useWorkspaceId } from '@/hooks/router';

import {
  CancelModalContentContainer,
  CancelModalFooterButtons,
  CancelModalHeader,
} from '.';

const churnSurveyReasons = [
  { label: 'Missing features', value: 'Missing features' },
  { label: 'Pricing too high', value: 'Pricing too high' },
  {
    label: 'Technical issues with the product',
    value: 'Technical issues with the product',
  },
  {
    label: 'We’re not hiring at the moment',
    value: 'We’re not hiring at the moment',
  },
  {
    label: 'We’re switching to another tool',
    value: 'We’re switching to another tool',
  },
  { label: 'Too hard to use', value: 'Too hard to use' },
  { label: 'Other', value: 'other' },
];

const schema = yup.object().shape({
  otherReason: yup.string().max(200, 'Maximum 200 characters.'),
  feedback: yup.string().max(400, 'Maximum 400 characters.'),
});

type TFormValues = {
  reason?: string;
  feedback?: string;
};

export const FinalCancelStepModal = ({
  open,
  setCurrentStep,
}: ICancelModalStepProps) => {
  const { addNotification } = useNotifications();
  const workspaceId = useWorkspaceId();
  const postFeedbackChurnSurvey = usePostFeedbackChurnSurvey();
  const amplitude = getAmplitudeClient();

  const deleteSubscriptionMutation = useDeleteSubscriptionMutation({
    onSuccess: () => {
      setCurrentStep(undefined);
      trackSwitchPlan('2024-09-free-ats');
      amplitude?.logEvent(`billing/subscription cancelled`, {
        'workspace id': workspaceId,
      });
      addNotification({ type: 'saved' });
    },
  });

  const handleSubmit = useCallback(
    async (values) => {
      await deleteSubscriptionMutation.mutate();
      postFeedbackChurnSurvey.mutate({
        workspaceId: workspaceId,
        reason: values.reason === 'other' ? values.otherReason : values.reason,
        feedback: values.feedback,
      });
    },
    [deleteSubscriptionMutation, postFeedbackChurnSurvey, workspaceId]
  );

  return (
    <DSModal
      fluid
      open={open}
      onClose={() => setCurrentStep(undefined)}
      contentStyle={{ width: '640px' }}
    >
      <CancelModalHeader>
        We’re sad to see you go!
        <DSModal.CloseButton
          small
          fixed
          onClick={() => setCurrentStep(undefined)}
        />
      </CancelModalHeader>
      <DSModal.Separator />
      <CancelModalContentContainer>
        Before you cancel, we’d appreciate your feedback about why you’re
        cancelling your subscription:
        <Formik<TFormValues>
          initialValues={{}}
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          {(form) => (
            <Form>
              <FormContainer>
                {churnSurveyReasons.map(({ label, value }) => (
                  <FormikRadioField
                    name="reason"
                    label={label}
                    value={value}
                    key={value}
                  />
                ))}

                {form.values.reason === 'other' && (
                  <OtherReasonInput
                    name="otherReason"
                    label=""
                    placeholder="My reason for cancellation..."
                  />
                )}
              </FormContainer>
              <FeedbackSubTitle>
                Please share any further details:
              </FeedbackSubTitle>
              <FormikTextAreaField name="feedback" inputHeight="100px" />

              <DSModal.Footer>
                <CancelModalFooterButtons>
                  <DSButton
                    variant="secondary"
                    type="button"
                    onClick={() => setCurrentStep(undefined)}
                  >
                    Stay on current plan
                  </DSButton>
                  <DSButton variant="primary-purple" type="submit">
                    Cancel subscription
                  </DSButton>
                </CancelModalFooterButtons>
              </DSModal.Footer>
            </Form>
          )}
        </Formik>
      </CancelModalContentContainer>
    </DSModal>
  );
};

const FormContainer = styled.div`
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const OtherReasonInput = styled(FormikInputField)`
  width: 326px;
`;

const FeedbackSubTitle = styled.div`
  padding: 12px 0;
  font-weight: 700;
`;
