import React from 'react';

export function GenericIllustration() {
  return (
    <svg
      width="260"
      height="392"
      viewBox="0 0 260 392"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_540_23729)">
        <rect width="260" height="392" rx="8" fill="#FCE5DB" />
        <rect
          width="354.835"
          height="263.249"
          rx="34.5783"
          transform="matrix(0.651918 -0.75829 0.750732 0.660608 -83.9766 244.068)"
          fill="white"
        />
        <path
          d="M180.732 163.053C184.551 163.196 187.297 166.404 187.988 170.185C188.21 171.389 188.326 172.631 188.326 173.859C188.345 200.012 188.34 226.166 188.34 252.319C188.34 252.861 188.354 253.403 188.308 253.936C187.812 259.565 184.762 262.414 179.107 262.424C163.363 262.447 147.618 262.433 131.874 262.433C114.044 262.433 96.2138 262.285 78.3883 262.512C72.0849 262.59 68.2241 259.329 68.2705 252.328C68.4188 229.645 68.3261 206.966 68.3214 184.283C68.3214 183.282 68.2519 182.282 68.2148 181.285C68.2797 177.973 68.2612 174.656 68.4234 171.348C68.6505 166.812 71.5547 163.206 76.0875 163.053C110.335 163.053 146.009 163.053 180.732 163.053ZM101.224 208.023C101.205 212.109 102.424 215.862 105.34 218.762C112.338 225.735 119.481 232.555 126.581 239.425C127.777 240.584 128.912 240.593 130.187 239.351C137.097 232.619 144.142 226.027 150.974 219.216C157.194 213.017 157.106 203.065 151.02 196.917C144.935 190.774 135.837 190.843 129.325 197.172C128.467 198.006 128.166 197.913 127.392 197.135C122.674 192.4 117.07 191.126 110.841 193.512C104.876 195.796 101.261 201.458 101.233 208.013L101.224 208.023Z"
          fill="#FFB8AF"
        />
        <path
          d="M85.7354 150.386C85.9084 144.447 85.6933 138.499 87.0399 132.616C91.0796 114.967 105.022 101.871 123.024 99.0717C125.011 98.7648 127.054 98.8206 129.069 98.709C152.751 99.2298 171.467 119.368 171.215 143.081C171.177 146.634 171.168 146.187 171.215 149.74C164.009 149.74 156.543 149.735 149.338 149.74C149.338 149.74 149.647 145.382 149.338 140.992C148.585 130.286 140.52 122.106 129.817 120.943C120.443 119.92 111.068 126.528 108.09 136.425C106.336 142.252 106.959 157.12 107.001 163.058C99.8052 163.063 92.9312 163.054 85.7354 163.058C85.9691 161.789 85.8803 151.66 85.7354 150.381V150.386Z"
          fill="#564260"
        />
        <path
          d="M100.983 208.323C101.011 201.749 104.64 196.076 110.629 193.791C116.883 191.4 122.509 192.677 127.245 197.422C128.023 198.202 128.325 198.29 129.186 197.459C135.724 191.117 144.862 191.047 150.967 197.204C157.077 203.36 157.165 213.337 150.921 219.549C144.062 226.374 136.989 232.981 130.051 239.727C128.772 240.971 127.636 240.962 126.431 239.801C119.307 232.916 112.132 226.082 105.106 219.094C102.179 216.183 100.96 212.427 100.974 208.332L100.983 208.323Z"
          fill="#412A4C"
        />
      </g>
      <defs>
        <clipPath id="clip0_540_23729">
          <rect width="260" height="392" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
