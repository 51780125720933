import styled from '@emotion/styled';

export const Header = styled.header`
  h1 {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    color: ${({ theme }) => theme.colors.purple[100]};
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    margin: 8px 0 0 0;
    color: ${({ theme }) => theme.colors.purple[60]};
  }
`;
