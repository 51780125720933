import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { useParams } from 'react-router';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { pluralizeWithCount, TId } from '@/features/common';
import { Counter } from '@/features/common/components/counter';
import { Loader } from '@/features/common/components/loader';
import { useWorkspacePermission } from '@/features/permissions';
import { usePreviewFeatureModal } from '@/features/preview-feature/hooks';
import {
  DEFAULT_CATEGORY_TAB,
  ISkill,
  Skill,
  SkillGroup,
  SkillTabs,
  TAB_LABEL_DICTIONARY,
  useSkillCategoryTabs,
  useSkillsQuery,
} from '@/features/skills';
import { TestType, useCreateTestMutation } from '@/features/test';
import { trackQueriedSkills } from '@/features/test-library/utils/track';
import {
  TestUpsell,
  useGenericPricingUpsellModalContext,
} from '@/features/upsell';
import { usePlanLimits, useTestJobOpeningLimit } from '@/hooks/planLimits';
import {
  useHistory,
  useJobOpeningIdOrNull,
  useWorkspaceId,
} from '@/hooks/router';

import { useAddTestToPipelineModalContext, useTestOnly } from '../../hooks';
import { EmptyState } from '../empty-state';
import { SearchBar } from '../test-library/ui';

import { SkillList } from './ui/skill-list';

const TEST_MAX_QUESTIONS: number = 3;

interface ISelectedSkill extends ISkill {
  questionCount: number;
}

export function HomeworkLibrary() {
  const canAccessFeature = usePlanLimits();
  const canCreateCustomTest = canAccessFeature('create_custom_test');
  const canCreateHomeworkTest = canAccessFeature('homework_test');
  const showPreviewFeatureModal = usePreviewFeatureModal();
  const { openPricingUpsell } = useGenericPricingUpsellModalContext();
  const history = useHistory();
  const canCreateJobOpening = useWorkspacePermission()(
    'job openings',
    'create'
  );
  const workspaceId = useWorkspaceId();
  const testOnly = useTestOnly();
  const jobOpeningId = useJobOpeningIdOrNull();
  const { categoryId } = useParams<{
    categoryId: TId;
  }>();
  const createTestMutation = useCreateTestMutation();
  const { open: openAddTestToPipelineModal } =
    useAddTestToPipelineModalContext();
  const amplitude = getAmplitudeClient();

  const canAddTest = useTestJobOpeningLimit();

  // --- Searching skills
  const [searchQuery, setSearchQuery] = useState<string>('');
  const handleSearchChange = useCallback(
    (event) => {
      const { value } = event.target;
      setSearchQuery(value);
      setActiveTab(value === '' ? DEFAULT_CATEGORY_TAB : '');
      trackQueriedSkills(value);
    },
    [setSearchQuery]
  );

  // --- Skills tabs
  const [activeTab, setActiveTab] = useState(DEFAULT_CATEGORY_TAB);
  const handleSelectTab = useCallback(
    (tabKey: string) => {
      setActiveTab(tabKey);
      setSearchQuery('');
    },
    [setActiveTab, setSearchQuery]
  );

  // --- Skills data
  const skillsQuery = useSkillsQuery({
    requestParams: { testType: TestType.Homework },
  });
  const { allTabs, allTabsKeys, filteredTabs, filteredTabsKeys } =
    useSkillCategoryTabs({
      skills: skillsQuery?.data ?? [],
      searchQuery,
    });

  // --- Skill selection
  const [selectedSkills, setSelectedSkills] = useState<ISelectedSkill[]>([]);
  const handleSkillSelection = useCallback(
    (selectedSkill: ISkill) => {
      const alreadySelected = selectedSkills.some(
        (currentlySelectedSkill) =>
          currentlySelectedSkill.id === selectedSkill.id
      );

      if (alreadySelected) {
        setSelectedSkills((prevSkills) =>
          prevSkills.filter((skill) => skill.id !== selectedSkill.id)
        );
        return;
      }

      if (selectedSkills.length >= TEST_MAX_QUESTIONS) {
        return;
      }

      setSelectedSkills((prevSkills) => [
        ...prevSkills,
        { ...selectedSkill, questionCount: 1 },
      ]);
    },
    [selectedSkills]
  );
  const handleUpdateSkillQuestions = useCallback(
    (skill: ISelectedSkill, questionCount: number) => {
      setSelectedSkills((prevSkills) =>
        prevSkills.map((prevSkill) =>
          prevSkill.id === skill.id
            ? { ...prevSkill, questionCount }
            : prevSkill
        )
      );
    },
    [setSelectedSkills]
  );
  const totals = useMemo(
    () =>
      selectedSkills.reduce(
        (acc, curr) => ({
          questions: acc.questions + curr.questionCount,
          duration: acc.duration + curr.questionCount,
        }),
        {
          questions: 0,
          duration: 0,
        }
      ),
    [selectedSkills]
  );

  const showEmptyState = useMemo(
    () =>
      !Object.values(filteredTabs as { [key: string]: ISkill[] }).some(
        (item) => item.length > 0
      ),
    [filteredTabs]
  );

  // --- Test creation
  const createTest = useCallback(
    async ({ jobOpeningId, categoryId, custom }) => {
      const skills: { id: string; difficulty: string }[] = [];
      selectedSkills.forEach((skill) => {
        for (let i = 0; i < skill.questionCount; i++) {
          skills.push({
            id: skill.id,
            difficulty: 'standard',
          });
        }
      });

      const createdTest = await createTestMutation.mutateAsync({
        jobOpeningId,
        attributes: {
          type: TestType.Homework,
          name: custom ? 'Custom test: Homework' : 'Homework',
          durationInSeconds: 0,
          percentToPass: 0,
          isQuestionRatingDisabled: false,
          pipelineStageId: categoryId,
          skills: custom ? [] : skills,
        },
      });

      if (custom) {
        history.push(
          `/admin/${workspaceId}/openings/${jobOpeningId}/test/${createdTest.id}/questions/add`
        );
      } else {
        history.push(
          `/admin/${workspaceId}/openings/${jobOpeningId}/test/${createdTest.id}/questions`
        );
      }
    },
    [createTestMutation, history, workspaceId, selectedSkills]
  );

  const handleTestCreation = useCallback(
    async ({ custom }: { custom: boolean }) => {
      if (custom && !canCreateCustomTest) {
        showPreviewFeatureModal.open('custom_test');
        return;
      }

      if (!canAddTest()) {
        openPricingUpsell('generic');
        return;
      }

      amplitude?.logEvent('job templates/homework create test', {
        skills: selectedSkills,
      });

      if (jobOpeningId && categoryId) {
        createTest({ jobOpeningId, categoryId, custom });
      } else {
        openAddTestToPipelineModal(
          (jobOpeningId, pipelineCategoryId) =>
            createTest({
              jobOpeningId,
              categoryId: pipelineCategoryId,
              custom,
            }),
          custom
        );
      }
    },
    [
      canAddTest,
      canCreateCustomTest,
      amplitude,
      selectedSkills,
      jobOpeningId,
      categoryId,
      showPreviewFeatureModal,
      openPricingUpsell,
      createTest,
      openAddTestToPipelineModal,
    ]
  );

  if (skillsQuery.isLoading) {
    return <SkillsLoader />;
  }

  if (!skillsQuery.isSuccess) {
    return null;
  }

  return canCreateHomeworkTest ? (
    <>
      <Wrapper>
        <SearchBarWrapper>
          <SearchBar
            placeholder="Search for skills"
            value={searchQuery}
            reset={() => {
              handleSelectTab(DEFAULT_CATEGORY_TAB);
            }}
            handleSearchChange={handleSearchChange}
            handleCreateCustomTest={() => handleTestCreation({ custom: true })}
          />
        </SearchBarWrapper>
        <TabsWrapper>
          <SkillTabs
            tabs={allTabsKeys}
            activeTab={activeTab}
            onTabClick={handleSelectTab}
          />
        </TabsWrapper>
        {!showEmptyState && (
          <Bottom>
            {searchQuery === '' ? (
              <SkillList
                skills={allTabs[activeTab]}
                selectedSkills={selectedSkills}
                onSkillSelect={handleSkillSelection}
                totalSelectedQuestions={totals.questions}
                maxQuestions={TEST_MAX_QUESTIONS}
              />
            ) : (
              <SkillGroup withHeader>
                {filteredTabsKeys.map((tabKey) =>
                  filteredTabs[tabKey].length > 0 ? (
                    <li key={tabKey}>
                      <h4>{TAB_LABEL_DICTIONARY[tabKey] ?? tabKey}</h4>
                      <SkillList
                        skills={filteredTabs[tabKey]}
                        selectedSkills={selectedSkills}
                        onSkillSelect={handleSkillSelection}
                        totalSelectedQuestions={totals.questions}
                        maxQuestions={TEST_MAX_QUESTIONS}
                      />
                    </li>
                  ) : null
                )}
              </SkillGroup>
            )}
            <SelectedSkills>
              <h3>Your skills (up to {TEST_MAX_QUESTIONS} questions):</h3>
              <ul>
                {selectedSkills.map((skill: ISelectedSkill) => (
                  <SelectedSkill key={skill.id}>
                    <Skill
                      skill={skill}
                      onClick={handleSkillSelection}
                      selected
                      inverted
                      htmlTag="div"
                    />
                    <Counter
                      max={
                        TEST_MAX_QUESTIONS -
                        totals.questions +
                        skill.questionCount
                      }
                      maxInWords={TEST_MAX_QUESTIONS}
                      onChange={(count: number) =>
                        handleUpdateSkillQuestions(skill, count)
                      }
                    />
                    <TimeSpan>
                      {pluralizeWithCount(skill.questionCount, 'hour')}
                    </TimeSpan>
                  </SelectedSkill>
                ))}
                <TotalTime>
                  <TimeSpan>
                    Total: {pluralizeWithCount(totals.duration, 'hour')}
                  </TimeSpan>
                </TotalTime>
              </ul>
              <ButtonsContainer>
                <DSButton
                  onClick={() => handleTestCreation({ custom: false })}
                  disabled={!canCreateJobOpening || selectedSkills.length === 0}
                  data-rh={
                    !canCreateJobOpening
                      ? 'Access restricted. Contact workspace admin to change your user rights.'
                      : null
                  }
                >
                  {testOnly ? 'Create test' : 'Add test'}
                </DSButton>
              </ButtonsContainer>
            </SelectedSkills>
          </Bottom>
        )}
      </Wrapper>
      {showEmptyState && (
        <EmptyStateWrapper>
          <EmptyState
            createCustomTest={() => handleTestCreation({ custom: true })}
          />
        </EmptyStateWrapper>
      )}
    </>
  ) : (
    <TestUpsell
      variant="homework"
      title="Dig deeper with homework assignments"
      text="Upgrade to Premium to access our library of homework assignments. Assess the candidate’s ability on a real-world task to better judge job performance."
    />
  );
}

const SkillsLoader = styled(Loader)`
  margin: 36px auto;
  display: block;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
`;

const SearchBarWrapper = styled.div`
  padding-left: 36px;
  padding-right: 36px;
`;

const TabsWrapper = styled.div`
  margin-bottom: 16px;
`;

const Bottom = styled.div`
  display: grid;
  grid-template-columns: 1fr 550px;
  padding: 0px 36px 36px 36px;

  & > ul {
    align-content: flex-start;
    border-right: 1px solid ${({ theme }) => theme.colors.purple[10]};
  }
`;
const EmptyStateWrapper = styled.div`
  padding-bottom: 36px;
`;

const SelectedSkills = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  padding-left: 16px;
  gap: 20px;

  h3 {
    font-size: 14px;
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: column;
    list-style: none;
  }
`;

const SelectedSkill = styled.li`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  justify-items: start;
`;

const TimeSpan = styled.span`
  font-weight: 700;
`;

const TotalTime = styled(SelectedSkill)`
  & span {
    grid-column: -2 / span 1;
  }
`;

const ButtonsContainer = styled.div`
  margin-top: 64px;
  align-self: flex-end;

  & > button:first-of-type {
    margin-right: 8px;
  }
`;
