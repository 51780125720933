import { userHasConsentedToStatistics } from '@/features/gdpr/utils/permissions';

let intercomLoaded = false;
function loadIntercom() {
  const appId = import.meta.env.VITE_INTERCOM_APP_ID;
  if (!intercomLoaded && appId && userHasConsentedToStatistics()) {
    try {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + appId;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);

        intercomLoaded = true;

        w.dispatchEvent(new Event('intercom-loaded'));
      }
    } catch {
      //pass
    }
  }
}

// try to load intercom script when the page loads
// If cookiebot is not available we add an event listener
loadIntercom();

// only initialize Intercom when Cookiebot is ready
// and if the user has consented to statistics
window.addEventListener('CookiebotOnConsentReady', loadIntercom);
