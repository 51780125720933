import * as React from 'react';
import styled from '@emotion/styled';
import { DSColumn, DSGrid, DSRow } from '@hundred5/design-system';

import { CardTokenFormFields } from '@/features/billing';

interface CardFormLayoutProps {
  fields: CardTokenFormFields;
  recurlyLoadFailed: boolean;
  recurlyHostedPage: string;
}

export function CardFormLayout(props: CardFormLayoutProps) {
  return (
    <div>
      {props.recurlyLoadFailed ? (
        <RecurlyLoadError>
          Please add your card info securely through our{' '}
          <a
            href={`https://${props.recurlyHostedPage}`}
            target="_blank"
            rel="noreferrer"
          >
            payment processor
          </a>
        </RecurlyLoadError>
      ) : (
        <>
          {' '}
          <DSGrid gap="16px">
            <DSRow gap="16px" xsWrap>
              <DSColumn size={12} xs={24}>
                {props.fields.firstName}
              </DSColumn>
              <DSColumn size={12} xs={24}>
                {props.fields.lastName}
              </DSColumn>
            </DSRow>
            <DSRow gap="16px" xsWrap>
              <DSColumn size={12} xs={24}>
                {props.fields.cardNumber}
              </DSColumn>

              <DSColumn size={12} xs={24}>
                <CardDetailsRow gap="12px">
                  {props.fields.month}
                  {props.fields.year}
                  {props.fields.cvv}
                </CardDetailsRow>
              </DSColumn>
            </DSRow>
            <DSRow gap="16px" xsWrap>
              <DSColumn size={12} xs={24}>
                {props.fields.vatNumber}
              </DSColumn>
            </DSRow>
            <SectionLabel>Billing info</SectionLabel>
            <DSRow gap="16px" xsWrap>
              <DSColumn size={12} xs={24}>
                {props.fields.address1}
              </DSColumn>
              <DSColumn size={12} xs={24}>
                {props.fields.address2}
              </DSColumn>
            </DSRow>
            <DSRow gap="16px" xsWrap>
              <DSColumn size={12} xs={24}>
                {props.fields.city}
              </DSColumn>
              <DSColumn size={12} xs={24}>
                {props.fields.state}
              </DSColumn>
            </DSRow>
            <DSRow gap="16px" xsWrap>
              <DSColumn size={12} xs={24}>
                {props.fields.postalCode}
              </DSColumn>
              <DSColumn size={12} xs={24}>
                {props.fields.country}
              </DSColumn>
            </DSRow>
          </DSGrid>
        </>
      )}
    </div>
  );
}

const SectionLabel = styled.div`
  font-weight: 700;
  font-size: 1rem;
  color: ${(props) => props.theme.typography.colorPrimary};
  margin-top: 8px;
`;

const CardDetailsRow = styled(DSRow)`
  margin-top: auto;
`;

const RecurlyLoadError = styled.div`
  margin-top: 20px;
`;
