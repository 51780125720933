import { useCallback, useEffect, useState } from 'react';

import { userHasConsentedToStatistics } from '@/features/gdpr/utils/permissions';
import { useMeQuery } from '@/features/me';
import { useLocation } from '@/hooks/router';

import { identifyContact, trackPageView } from '../utils/hubspot';

export function useHubspotTracking() {
  const location = useLocation();
  const meQuery = useMeQuery();

  const [isHubspotEnabled, setIsHubspotEnabled] = useState(false);
  const handleHubspotLoad = useCallback(() => {
    setIsHubspotEnabled(true);
  }, []);

  useEffect(() => {
    window.addEventListener('hubspot-loaded', handleHubspotLoad);
    return () =>
      window.removeEventListener('hubspot-loaded', handleHubspotLoad);
  }, [handleHubspotLoad]);

  useEffect(() => {
    if (
      isHubspotEnabled &&
      meQuery.data?.email &&
      userHasConsentedToStatistics()
    ) {
      identifyContact({
        email: meQuery.data.email,
        firstname: meQuery.data.name,
      });
    }
  }, [meQuery.data?.email, meQuery.data?.name, isHubspotEnabled]);

  useEffect(() => {
    if (isHubspotEnabled && userHasConsentedToStatistics()) {
      trackPageView(location.pathname, meQuery.data?.email);
    }
  }, [isHubspotEnabled, location.pathname, meQuery.data?.email]);
}
