import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { TId } from '@/features/common';
import { ME_QUERY_KEY } from '@/features/me';
import { fetchMe } from '@/features/me/api';
import {
  createWorkspace,
  deleteWorkspace,
  IWorkspace,
  updateWorkspace,
  startWorkspaceFreeTrial,
  finishWorkspaceFreeTrial,
} from '@/features/workspace';

export const useWorkspaceQuery = ({ workspaceId }: { workspaceId?: TId }) =>
  useQuery(ME_QUERY_KEY, fetchMe, {
    enabled: Boolean(workspaceId),
    select: (data) =>
      data.workspaces?.find((workspace) => workspace.id === workspaceId),
  });

interface IMutationParams {
  onSuccess?: () => void;
}

export const useCreateWorkspaceMutation = ({
  onSuccess,
}: {
  onSuccess: (workspace: IWorkspace) => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation(createWorkspace, {
    onSuccess: (workspace) => {
      queryClient.invalidateQueries(ME_QUERY_KEY);
      onSuccess?.(workspace);
    },
  });
};

export const useUpdateWorkspaceMutation = ({ onSuccess }: IMutationParams) => {
  const queryClient = useQueryClient();

  return useMutation(updateWorkspace, {
    onSuccess: () => {
      queryClient.invalidateQueries(ME_QUERY_KEY);
      onSuccess?.();
    },
  });
};

export const useStartWorkspaceFreeTrialMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(startWorkspaceFreeTrial, {
    onSuccess: () => {
      queryClient.invalidateQueries(ME_QUERY_KEY);
    },
    ...(options as any),
  });
};

export const useFinishWorkspaceFreeTrialMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(finishWorkspaceFreeTrial, {
    onSuccess: () => {
      queryClient.invalidateQueries(ME_QUERY_KEY);
    },
    ...(options as any),
  });
};

export const useDeleteWorkspaceMutation = ({ onSuccess }: IMutationParams) => {
  const queryClient = useQueryClient();

  return useMutation(deleteWorkspace, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(ME_QUERY_KEY);
      onSuccess?.();
    },
  });
};
