import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';

import { Icon } from '@/features/common';
import { useMeQuery } from '@/features/me';

import sandClock from '../assets/sand-clock.svg';

import { Content, Header, Illustration } from './';

interface FreeTrialProps {
  onContinue: () => void;
}

export const FreeTrial = ({ onContinue }: FreeTrialProps) => {
  const { data: meQuery } = useMeQuery();

  return (
    <>
      <Content>
        <Header>
          <h1>Your free Starter trial has ended</h1>
          <p>
            Your free Starter trial has ended. To get the most out of Toggl
            Hire, upgrade to Starter or Premium.
          </p>
        </Header>

        <ListContainer>
          <ListTitle>During your Starter trial you could:</ListTitle>
          <List>
            <ListItem>
              <Icon
                fontSize={14}
                icon={regular('briefcase-blank')}
                fixedWidth
              />
              <span>Hire faster with 3 job openings</span>
            </ListItem>
            <ListItem>
              <Icon fontSize={14} icon={regular('wrench')} fixedWidth />
              <span>Add unlimited, fully-customizable tests</span>
            </ListItem>
            <ListItem>
              <Icon fontSize={14} icon={regular('message-bot')} fixedWidth />
              <span>Save time with async interviews, automation and AI</span>
            </ListItem>
            <ListItem>and much more!</ListItem>
          </List>
        </ListContainer>

        <footer>
          <DSButton
            variant="primary-purple"
            fullWidth
            onClick={() => onContinue()}
          >
            Continue
          </DSButton>

          {meQuery?.workspaces && meQuery.workspaces?.length > 1 && (
            <Info>
              <Icon icon={regular('circle-info')} color="purple-60" />
              <span>
                To switch to a different workspace, first choose a subscription
                for this workspace or continue on the Free plan.
              </span>
            </Info>
          )}
        </footer>
      </Content>

      <Illustration src={sandClock} alt="" />
    </>
  );
};

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ListTitle = styled.h2`
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.purple[100]};
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ListItem = styled.li`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.purple[100]};

  svg {
    margin-top: 4px;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  font-style: italic;
  margin-top: 16px;
  gap: 4px;
  color: ${({ theme }) => theme.colors.purple[60]};

  svg {
    margin-top: 2px;
  }
`;
