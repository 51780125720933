import { camelizeKeys } from 'humps';
import { mapValues } from 'lodash';
import { normalize } from 'normalizr';

import { TQuestionType } from '@/features/questions';
import { QuestionAnswer, QuestionAnswerScore } from '@/types/answer';
import {
  Candidate,
  CandidateContactInfo,
  CandidateField,
  CandidateHireState,
  CandidateTest,
  CandidateTestState,
  CandidateTestStatus,
  CandidateVideoFeedbackParams,
  ContactCandidateParams,
} from '@/types/candidate';
import { TestType } from '@/types/h5test';
import { Id } from '@/types/misc';
import { Question, QuestionOption } from '@/types/question';
import { CandidateFilter } from '@/types/reports';

import api from './api';
import { candidate as candidateSchema } from './schema';

export type ApiCandidateContactInfo = {
  contactEmail: string;
  phone: string;
  fullName: string;
  firstName: string;
  lastName: string;
  city: string;
  country: string;
  linkedin: string;
  github: string;
  website: string;
};

export type ApiCandidateField = {
  id: number;
  name: string;
  value: string;
};

export type ApiCandidate = {
  id: number;
  createdAt: Date;
  name: string;
  email: string;
  url: string;
  notes: string;
  rating: number;
  jobOpeningId?: Id;
  contactInfo: ApiCandidateContactInfo;
  candidateFields: ApiCandidateField[] | null;
  categoryId: number | null;
  orderWeight: number | null;
  notesTotal: number;
  unreadNotes: number;
  hireState: CandidateHireState;
  isDemo: boolean;
  seen: boolean;
  isUnlocked: boolean;
  isVideoIntroUnlocked: boolean;
  tests?: number[];
  originalPercentToPass?: number;
  percent?: number;
  points?: number;
  startedAt?: number;
  finishedAt?: number;
  isSuspicious?: boolean;
  copyCount?: number;
  pasteCount?: number;
  similarEmail?: boolean;
  fingerprintClash?: boolean;
  quickTestSubmit?: boolean;
  userMarkedSuspicious?: boolean | null;
  candidateState?: CandidateTestState;
  isEvaluated?: boolean;
  isRecommendation: boolean;
};

export const parseCandidate = (
  apiCandidate: ApiCandidate,
  jobOpeningId?: Id
): Candidate => {
  const candidate: Candidate = {
    id: String(apiCandidate.id),
    createdAt: apiCandidate.createdAt,
    name: apiCandidate.name,
    email: apiCandidate.email,
    url: apiCandidate.url,
    notes: apiCandidate.notes,
    rating: apiCandidate.rating,
    tests: apiCandidate.tests
      ? apiCandidate.tests.map((test) => String(test))
      : [],
    contact: parseCandidateContactInfo(apiCandidate.contactInfo),
    candidateFields: parseCandidateFields(apiCandidate.candidateFields),
    jobOpening:
      jobOpeningId ||
      (apiCandidate.jobOpeningId ? String(apiCandidate.jobOpeningId) : ''),
    category:
      apiCandidate.categoryId != null ? String(apiCandidate.categoryId) : null,
    weight: apiCandidate.orderWeight,
    notesTotal: apiCandidate.notesTotal,
    unreadNotes: apiCandidate.unreadNotes,
    hireState: apiCandidate.hireState ?? null,
    isDemo: apiCandidate.isDemo,
    seen: apiCandidate.seen,
    unlocked: apiCandidate.isUnlocked,
    videoIntroUnlocked: apiCandidate.isVideoIntroUnlocked,
    suspicious: apiCandidate.isSuspicious,
    copyCount: apiCandidate.copyCount,
    pasteCount: apiCandidate.pasteCount,
    similarEmail: apiCandidate.similarEmail,
    fingerprintClash: apiCandidate.fingerprintClash,
    quickTestSubmit: apiCandidate.quickTestSubmit,
  };

  if (apiCandidate.originalPercentToPass) {
    candidate.originalThreshold = apiCandidate.originalPercentToPass;
  }

  if (apiCandidate.startedAt) {
    candidate.startedAt = new Date(apiCandidate.startedAt);
  }

  if (apiCandidate.finishedAt) {
    candidate.finishedAt = new Date(apiCandidate.finishedAt);
  }

  if (apiCandidate.percent != null && apiCandidate.points != null) {
    candidate.score = {
      percent: apiCandidate.percent,
      points: apiCandidate.points,
    };
  }

  return candidate;
};

const parseCandidateTest = (test: {
  jobOpeningId: number;
  candidateId: number;
  startedAt?: number;
  finishedAt?: number;
  percent: number;
  points: number;
  percentToPass?: number;
  questions: number[];
  answers: number[];
  testId: number;
  status: CandidateTestStatus;
  testType: TestType;
  candidateState?: CandidateTestState;
  isEvaluated?: boolean;
}): CandidateTest => ({
  jobOpeningId: String(test.jobOpeningId),
  candidateId: String(test.candidateId),
  testId: String(test.testId),
  startedAt: new Date(test.startedAt || 0),
  finishedAt: test.finishedAt ? new Date(test.finishedAt) : undefined,
  score: { percent: test.percent, points: test.points },
  percentToPass: test.percentToPass,
  questions: test.questions ? test.questions.map((q) => String(q)) : [],
  answers: test.answers ? test.answers.map((a) => String(a)) : [],
  status: test.status,
  type: test.testType,
  candidateState: test.candidateState ?? null,
  testIsEvaluated: test.isEvaluated ?? false,
});

const parseScore = ({
  scoreAsPercents,
  scoreAsPoints,
}: {
  scoreAsPercents: number | null;
  scoreAsPoints: number | null;
}): QuestionAnswerScore | null => {
  if (scoreAsPercents != null && scoreAsPoints != null) {
    return { percentage: scoreAsPercents, points: scoreAsPoints };
  } else {
    return null;
  }
};

const parseFullAnswer = (answer: {
  id: Id | null;
  questionId: number;
  candidateId: number;
  testId: number;
  scoreAsPercents: number | null;
  scoreAsPoints: number | null;
  freeFormAnswer?: string;
  chosenOptions?: { questionOptionId: number }[] | null;
  recordingUrl: string | null;
  recordingDurationInSeconds: number | null;
  isRecordingProcessed: boolean | null;
}): QuestionAnswer => {
  const id = answer.id && String(answer.id);
  const question = String(answer.questionId);
  const candidate = String(answer.candidateId);
  const test = String(answer.testId);
  const score = parseScore(answer);

  if (answer.freeFormAnswer) {
    return {
      id,
      type: 'text',
      test,
      question,
      candidate,
      score,
      text: answer.freeFormAnswer,
    };
  } else if (answer.chosenOptions) {
    return {
      id,
      type: 'option',
      test,
      question,
      candidate,
      score,
      options: answer.chosenOptions.map(({ questionOptionId }) =>
        String(questionOptionId)
      ),
    };
  } else if (answer.recordingUrl) {
    return {
      id,
      type: 'video',
      test,
      question,
      candidate,
      score,
      recordingUrl: answer.recordingUrl,
      recordingDurationInSeconds: answer.recordingDurationInSeconds,
      recordingProcessed: answer.isRecordingProcessed,
    };
  } else {
    return { id, type: 'general', test, question, candidate, score };
  }
};

const parseCandidateQuestion = (item: {
  id: number;
  testId: number;
  title: string;
  description: string;
  questionType: string;
  orderWeight: number;
  points: number;
  testGenSkillId: number;
  isStatic: boolean;
}): Question => {
  const {
    id,
    title,
    description,
    questionType,
    orderWeight,
    points,
    testGenSkillId,
    isStatic,
    testId,
  } = item;

  return {
    id: String(id),
    title,
    description,
    type: questionType as TQuestionType,
    weight: orderWeight,
    points,
    test: String(testId),
    skill: testGenSkillId ? String(testGenSkillId) : null,
    static: isStatic,
  };
};

const parseCandidateQuestionOption = (item: {
  id: number;
  points: number;
  content: string;
  orderWeight: number;
  questionId: number;
}): QuestionOption => {
  const { id, points, content, orderWeight, questionId } = item;
  return {
    id: String(id),
    points,
    content,
    weight: orderWeight,
    question: String(questionId),
  };
};

export const getCandidateFilterQueryParams = (
  filter?: CandidateFilter
): { [param: string]: string } | undefined => {
  switch (filter) {
    case 'all':
      return { filter: 'all' };
    case 'possible_hires':
      return { filter: 'possible-hires' };
    case 'invited':
      return { filter: 'invited' };
    case 'not_rejected':
      return { filter: 'not-rejected' };
    case 'rejected':
      return { filter: 'rejected' };
    case 'archived':
      return { filter: 'archived' };
    case 'maybe':
      return { filter: 'maybe' };
    case 'not_finished':
      return { filter: 'not-finished' };
    case 'new':
      return { filter: 'not-rejected', seen: 'false' };
    case 'over_40':
      return { filter: 'not-rejected', 'min-percentage': '40' };
    case 'over_60':
      return { filter: 'not-rejected', 'min-percentage': '60' };
    case 'over_80':
      return { filter: 'not-rejected', 'min-percentage': '80' };
    case 'notes':
      return { filter: 'notes' };
    case 'suspicious':
      return { filter: 'suspicious' };
  }
};

export const normalizeCandidate = (
  candidateId: Id,
  data: any
): FetchCandidateResponse => {
  const {
    entities: {
      candidates,
      candidateTests,
      candidateQuestions,
      candidateQuestionOptions,
      answers,
    },
  }: {
    entities: {
      candidates: { [id: string]: any };
      candidateTests: { [id: string]: any };
      candidateQuestions: { [id: string]: any };
      candidateQuestionOptions: { [id: string]: any };
      answers: { [id: string]: any };
    };
  } = normalize(data, candidateSchema);

  const candidate = candidates[candidateId];
  const parsedCandidate = parseCandidate(candidate);
  const parsedQuestions = mapValues(candidateQuestions, (item) =>
    parseCandidateQuestion(item)
  );
  const parsedQuestionOptions = mapValues(candidateQuestionOptions, (item) =>
    parseCandidateQuestionOption(item)
  );
  const parsedAnswers = mapValues(answers, (item) => parseFullAnswer(item));
  const parsedTags = candidate.tags
    ? candidate.tags.map((id) => String(id))
    : [];
  const parsedTests = mapValues(candidateTests, (item) =>
    parseCandidateTest(item)
  );

  return {
    candidate: parsedCandidate,
    candidateTests: parsedTests,
    questions: parsedQuestions,
    questionOptions: parsedQuestionOptions,
    answers: parsedAnswers,
    tags: parsedTags,
  };
};

export type FetchCandidateResponse = {
  candidate: Candidate;
  candidateTests: { [id: string]: CandidateTest };
  questions: { [id: string]: Question };
  questionOptions: { [id: string]: QuestionOption };
  answers: { [id: string]: QuestionAnswer };
  tags: Id[];
};

const parseCandidateContactInfo = (
  apiCandidateContactInfo: ApiCandidateContactInfo
): CandidateContactInfo => ({
  email: apiCandidateContactInfo.contactEmail,
  fullName: apiCandidateContactInfo.fullName,
  firstName: apiCandidateContactInfo.firstName,
  lastName: apiCandidateContactInfo.lastName,
  city: apiCandidateContactInfo.city,
  country: apiCandidateContactInfo.country,
  phone: apiCandidateContactInfo.phone,
  linkedIn: apiCandidateContactInfo.linkedin,
  gitHub: apiCandidateContactInfo.github,
  website: apiCandidateContactInfo.website,
});

const parseCandidateFields = (
  apiCandidateFields: ApiCandidateField[] | null
): CandidateField[] =>
  apiCandidateFields
    ? apiCandidateFields.map((field) => ({
        id: field.id,
        name: field.name,
        value: field.value,
      }))
    : [];

export async function sendCandidateVideoFeedback(
  params: CandidateVideoFeedbackParams
): Promise<void> {
  await api.post(
    `/test-takers/${params.candidateId}/feedback`,
    {
      test_id: Number(params.testId),
      subject: params.subject,
      body: params.message,
      test_taker_tag_ids: params.candidateTags?.map((id) => Number(id)),
      send_at: params.sendAt?.toISOString(),
    },
    {
      captchaAction: 'video_feedback',
    }
  );
}

export async function contactCandidate(
  params: ContactCandidateParams
): Promise<FetchCandidateResponse | null> {
  const response = await api.post(
    `/test-takers/${params.candidateId}/contact`,
    {
      subject: params.subject,
      message: params.message,
      test_taker_tag_ids: params.candidateTags?.map((id) => Number(id)),
      send_at: params.sendAt?.toISOString(),
    },
    {
      captchaAction: 'contact_candidate',
    }
  );

  const data = camelizeKeys(response.data) as any;

  return data && normalizeCandidate(params.candidateId, data);
}
