import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { BillingPlan, billingPlans, Coupon } from '@/features/billing';

interface PriceProps {
  plan: BillingPlan;
  coupon?: Coupon | null;
  variant?: 'downgrade';
}

export function Price({ plan, coupon, variant }: PriceProps) {
  const billingPlan = billingPlans[plan];
  if (!billingPlan) return null;

  const showDiscount = !!coupon && !!billingPlan.price;
  const discountedPrice =
    coupon?.discount.type === 'percent'
      ? (billingPlan.price! * (100 - coupon?.discount.percent)) / 100
      : coupon?.discount.type === 'dollars' &&
        (billingPlan.type === 'Annually'
          ? (billingPlan.price! - coupon?.discount.amount! / 1200).toFixed(2)
          : billingPlan.type === 'Monthly' &&
            billingPlan.price! - coupon?.discount.amount! / 100);

  return (
    <Container variant={variant}>
      <div>
        {billingPlan.name} (<PlanType>{billingPlan.type}</PlanType>)
      </div>
      <Prices>
        {showDiscount && (
          <DiscountedPrice>
            {`$${discountedPrice || 0}
            /month`}
          </DiscountedPrice>
        )}
        <OriginalPrice showDiscount={showDiscount}>
          ${billingPlan.price}
          /month
        </OriginalPrice>
      </Prices>
    </Container>
  );
}

const Container = styled.div<{ variant?: 'downgrade' }>`
  margin-bottom: 8px;
  display: flex;
  align-items: flex-start;
  position: relative;
  justify-content: space-between;
  font-weight: 700;

  ${(props) =>
    props.variant &&
    css`
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `}
`;

const OriginalPrice = styled.div<{ showDiscount: boolean }>`
  text-decoration: ${(props) => props.showDiscount && 'line-through'};
`;

const DiscountedPrice = styled.div`
  margin-bottom: 12px;
`;

const Prices = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 120px;
  font-size: 16px;
`;

const PlanType = styled.span`
  text-transform: lowercase;
`;
