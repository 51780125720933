import { useCallback } from 'react';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { useTestPage } from '@/features/test';

export function useLogFraudDetectionSettings() {
  const { workspace, jobOpening, test } = useTestPage();
  const amplitude = getAmplitudeClient();

  return useCallback(
    (settingName: string) => {
      amplitude?.logEvent(`fraud detection settings/${settingName}`, {
        'workspace id': workspace.id,
        'job opening id': jobOpening.id,
        'test id': test.id,
        'subscription plan': workspace.subscriptionPlan,
      });
    },
    [
      amplitude,
      workspace.id,
      workspace.subscriptionPlan,
      jobOpening.id,
      test.id,
    ]
  );
}
