import React from 'react';
import styled from '@emotion/styled';

import { isJobOpeningPaused } from '@/features/job-opening';

interface JobOpeningPausedTagProps {
  pausedAt?: Date | null;
  tooltipPosition?: string;
}

export function JobOpeningPausedTag({
  pausedAt,
  tooltipPosition = 'bottom',
}: JobOpeningPausedTagProps) {
  return isJobOpeningPaused(pausedAt) ? (
    <Tag
      data-rh-at={tooltipPosition}
      data-rh="Candidates will not be able to apply to this job. You can still invite existing candidates to the next stages."
    >
      Paused
    </Tag>
  ) : null;
}

const Tag = styled.span`
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.orange[120]};
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  padding: 0 8px;
  border-radius: 8px;
`;
