import api from '@/api/api';
import { ICandidate, ICandidateAttributes } from '@/features/candidate';
import { TId } from '@/features/common';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

const BASE_URL = import.meta.env.VITE_API_URL + '/api/v1';

export async function fetchCandidate(params: {
  candidateId: TId;
}): Promise<ICandidate> {
  const response = await api.get(`/test-takers/${params.candidateId}`);

  return parseAPIData(response.data);
}

// To be deleted unlocked_candidates
export async function unlockCandidate(params: {
  candidateId: TId;
}): Promise<{ unlockableTestTakersRemaining: number }> {
  const response = await api.post(`/test-takers/${params.candidateId}/unlock`);

  return parseAPIData(response.data);
}

export async function updateCandidate(params: {
  candidateId: TId;
  attributes: Partial<ICandidateAttributes>;
}): Promise<ICandidate> {
  const response = await api.patch(
    `/test-takers/${params.candidateId}`,
    serialiseAPIData(params.attributes)
  );

  return parseAPIData(response.data);
}

export async function exportCandidate(params: {
  candidateId: TId;
}): Promise<string> {
  const response = await api.post(`/test-takers/${params.candidateId}/export`);
  const token = response.data.token;

  return `${BASE_URL}/test-takers/${params.candidateId}/export?token=${token}`;
}

export async function deleteCandidate(params: {
  candidateId: TId;
}): Promise<boolean> {
  await api.delete(`/test-takers/${params.candidateId}`);
  return true;
}

export async function rejectCandidate(params: {
  candidateId: TId;
  email: {
    subject: string;
    message: string;
    candidateTags?: TId[];
    sendAt?: Date;
  } | null;
}): Promise<ICandidate> {
  const response = await api.post(
    `/test-takers/${params.candidateId}/reject`,
    {
      email:
        params.email &&
        serialiseAPIData(params.email, {
          replacements: [['candidate_tags', 'test_taker_tag_ids']],
        }),
    },
    {
      captchaAction: 'reject',
    }
  );

  return parseAPIData(response.data);
}

export async function restoreCandidate(params: {
  candidateId: TId;
}): Promise<ICandidate> {
  const response = await api.post(`/test-takers/${params.candidateId}/restore`);
  return parseAPIData(response.data);
}

export async function markCandidateAsSeen(params: {
  candidateId: TId;
}): Promise<{ seenAt: Date }> {
  const response = await api.post(
    `/test-takers/${params.candidateId}/mark-as-seen`
  );
  return parseAPIData(response.data);
}
