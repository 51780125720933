import { BillingPlan, Coupon } from '@/features/billing';

export function isValidCoupon(coupon: Coupon, plan: BillingPlan): boolean {
  if (coupon.state === 'redeemable') {
    if (coupon.appliesToAllPlans) {
      return true;
    } else if (coupon.plans && coupon.plans.includes(plan)) {
      return true;
    }
  } else {
    return false;
  }
  return false;
}
