import React, { useMemo } from 'react';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import {
  useDeleteSubscriptionMutation,
  useSubscribeModal,
} from '@/features/billing';
import { ConfirmModal } from '@/features/common';
import { useMeQuery } from '@/features/me';
import { useNotifications } from '@/features/notifications';
import { useHistory, useWorkspaceId } from '@/hooks/router';

interface ResumeModalProps {
  resumeModalState: boolean;
  setResumeModalState: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ResumeModal({
  resumeModalState,
  setResumeModalState,
}: ResumeModalProps) {
  const { addNotification } = useNotifications();
  const workspaceId = useWorkspaceId();
  const { openSubscribeModal } = useSubscribeModal();
  const history = useHistory();
  const meQuery = useMeQuery();
  const amplitude = getAmplitudeClient();

  const deleteSubscriptionMutation = useDeleteSubscriptionMutation({
    onSuccess: () => {
      currentPlan && openSubscribeModal(currentPlan);
      amplitude?.logEvent(`billing/subscription resumed`, {
        'workspace id': workspaceId,
      });
      addNotification({ type: 'saved' });
      history.push(`/admin/${workspaceId}/settings/billing`);
    },
  });

  const currentPlan = useMemo(() => {
    return (
      meQuery.data?.workspaces?.find(
        (workspace) => workspace.id === workspaceId
      )?.subscriptionPlan ?? null
    );
  }, [workspaceId, meQuery.data?.workspaces]);

  return (
    <ConfirmModal
      open={resumeModalState}
      action="Confirm"
      onConfirm={() => {
        setResumeModalState(false);
        deleteSubscriptionMutation.mutate();
      }}
      onClose={() => setResumeModalState(false)}
      confirmation="Resume"
    >
      <h3>Are you sure?</h3>
      <p>You are about to resume your subscription. Do you want to proceed?</p>
    </ConfirmModal>
  );
}
