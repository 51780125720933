import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  DSButton,
  DSField,
  DSStyledContent,
  DSTestLandingPage,
} from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { Markdown, PromptIfDirty, TId } from '@/features/common';
import {
  createEditorState,
  CustomTextEditor,
  getEditorMarkdown,
} from '@/features/common/components/custom-text-editor';
import { trackIntercomEvent } from '@/features/intercom';
import { useJobOpeningQuery } from '@/features/job-opening';
import {
  useJobOpeningPageAnalytics,
  useJobOpeningPageEventData,
} from '@/features/job-opening/utils/job-opening-page-analytics';
import { useNotifications } from '@/features/notifications';
import { useJobOpeningPermission } from '@/features/permissions';
import { useSkillsQuery } from '@/features/skills';
import { useTestQuery, useUpdateTestMutation } from '@/features/test';
import { useUpdateWorkspaceMutation, useWorkspace } from '@/features/workspace';

import { replaceScreenTemplateTags } from './landing-page-settings.utils';
import { ContactInfo, LoginMethods, Logo, TitleImage } from './ui';

interface LandingPageSettingsProps {
  testId: TId;
}

const validationSchema = object().shape({
  screenContent: string().max(
    6000,
    'Character limit reached. Please try shortening the page content!'
  ),
});

export function LandingPageSettings({ testId }: LandingPageSettingsProps) {
  useJobOpeningPageAnalytics('job opening landing page');
  const { addNotification } = useNotifications();
  const eventData = useJobOpeningPageEventData();
  const canUpdateJobOpening = !!useJobOpeningPermission()(
    'job opening',
    'update'
  );
  const amplitude = getAmplitudeClient();
  const [editing, setEditing] = useState(false);
  const [editorState, setEditorState] = useState(createEditorState(''));

  const workspace = useWorkspace();
  const jobOpeningQuery = useJobOpeningQuery();
  const testQuery = useTestQuery({ testId });
  const skillsQuery = useSkillsQuery({
    requestParams: { testType: testQuery.data?.type },
  });

  const updateTestMutation = useUpdateTestMutation();
  const updateWorkspaceMutation = useUpdateWorkspaceMutation({});

  if (
    !workspace ||
    !jobOpeningQuery.isSuccess ||
    !testQuery.isSuccess ||
    !skillsQuery.isSuccess
  ) {
    return null;
  }

  const initialValues = {
    titleImageUrl: testQuery.data.backgroundPictureUrl || '',
    titleImagePosition: testQuery.data.backgroundPosition,
    logoUrl: workspace.logoUrl,
    screenContent: testQuery.data.screens.welcome?.content,
  };

  const handleEdit = () => {
    setEditing(true);
    setEditorState(createEditorState(initialValues.screenContent ?? ''));

    if (eventData) {
      amplitude?.logEvent('job setup/edit landing page', eventData);
    }
  };

  const handleCancel = (resetForm: () => void) => {
    resetForm();
    setEditing(false);
  };

  const handleSave = async (values: any) => {
    const { screenContent, ...settings } = values;
    const updatedWorkspace = { ...workspace, logoUrl: settings.logoUrl };

    const updatedTest = {
      backgroundPictureUrl: settings.titleImageUrl,
      backgroundPosition: settings.titleImagePosition,
      screens: {
        ...testQuery.data.screens,
        welcome: {
          settings: testQuery.data.screens.welcome?.settings || {},
          content: screenContent,
        },
      },
    };

    await updateWorkspaceMutation.mutateAsync({
      workspaceId: workspace.id,
      workspace: updatedWorkspace,
    });
    await updateTestMutation.mutateAsync({ testId, test: updatedTest });

    if (eventData) {
      amplitude.logEvent('job setup/save landing page', eventData);
    }

    if (!jobOpeningQuery.data.isDemo) {
      trackIntercomEvent('edited_job_description');
    }

    addNotification({ type: 'saved' });
    setEditing(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={handleSave}
    >
      {(form) => (
        <Form>
          <PromptIfDirty />
          <LandingPageSettingsHeader>
            <Description>
              This is what the candidate will see when they open your job.
            </Description>
            <ActionButtons>
              {editing ? (
                <>
                  <DSButton
                    variant="secondary"
                    onClick={() => handleCancel(form.resetForm)}
                    disabled={form.isSubmitting}
                  >
                    Cancel
                  </DSButton>
                  <DSButton
                    type="submit"
                    disabled={form.isSubmitting || !form.dirty}
                  >
                    Save
                  </DSButton>
                </>
              ) : (
                <DSButton
                  variant="secondary"
                  onClick={handleEdit}
                  disabled={!canUpdateJobOpening}
                  data-rh={
                    !canUpdateJobOpening
                      ? 'Only admins can edit job description'
                      : null
                  }
                >
                  Edit
                </DSButton>
              )}
            </ActionButtons>
          </LandingPageSettingsHeader>

          <LandingPageSettingsContent>
            <DSTestLandingPage embedded>
              <TitleImage
                url={form.values.titleImageUrl}
                position={form.values.titleImagePosition}
                editable={editing}
                onUpdate={(url) => {
                  form.setFieldValue('titleImageUrl', url);
                }}
                onReposition={(position) => {
                  form.setFieldValue('titleImagePosition', position);
                }}
              />

              <DSTestLandingPage.Header>
                <Logo
                  url={form.values.logoUrl}
                  editable={editing}
                  onUpdate={(url) => {
                    form.setFieldValue('logoUrl', url);
                  }}
                />
              </DSTestLandingPage.Header>

              <DSTestLandingPage.Main>
                <DSStyledContent>
                  {editing ? (
                    <DSField
                      for="screenContent"
                      error={
                        form.touched.screenContent &&
                        !!form.errors.screenContent ? (
                          <>{form.errors.screenContent}</>
                        ) : null
                      }
                    >
                      <CustomTextEditor
                        minHeight="160px"
                        state={editorState}
                        onChange={(state) => {
                          form.setFieldValue(
                            'screenContent',
                            getEditorMarkdown(state)
                          );
                          setEditorState(state);
                        }}
                        error={
                          form.touched.screenContent &&
                          !!form.errors.screenContent
                        }
                      />
                    </DSField>
                  ) : (
                    <Markdown>
                      {replaceScreenTemplateTags(
                        workspace,
                        jobOpeningQuery.data,
                        testQuery.data,
                        testQuery.data.screens.welcome?.content || '',
                        skillsQuery.data
                      )}
                    </Markdown>
                  )}
                </DSStyledContent>

                <LoginMethods methods={jobOpeningQuery?.data?.loginMethods} />
              </DSTestLandingPage.Main>

              <DSTestLandingPage.Footer>
                <ContactInfo
                  name={workspace.name}
                  email={jobOpeningQuery.data.contactEmail}
                />
              </DSTestLandingPage.Footer>
            </DSTestLandingPage>
          </LandingPageSettingsContent>
        </Form>
      )}
    </Formik>
  );
}

const LandingPageSettingsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.typography.colorPrimary};
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 4px;
`;

const LandingPageSettingsContent = styled.div`
  background-color: ${(props) => props.theme.colors.purple[10]};
  padding: 16px;
  border-radius: 8px;
`;
