import React, { useState } from 'react';

import {
  CardInfoChallengeError,
  CardInfoUpdateError,
  CardTokenFormBag,
  PaymentChallenge,
  useUpdateCardInfoMutation,
} from '@/features/billing';
import { TId } from '@/features/common';
import { useApiError } from '@/hooks/api';
import { RecurlyError, RecurlyToken } from '@/recurly';

import { CardTokenForm } from '../card-token-form';

export interface CardFormProps {
  workspaceId: TId;
  onFinish?: () => void;
  renderCardForm: (bag: CardTokenFormBag) => React.ReactElement;
  renderChallenge: (challenge: React.ReactElement) => React.ReactElement;
  formRef?: any;
}

interface Challenge {
  initialToken: string;
  challengeToken: string;
}

type CardFormError = RecurlyError | CardInfoUpdateError | true;

export const CardForm = (props: CardFormProps) => {
  const handleApiError = useApiError();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<CardFormError | null>(null);
  const [challenge, setChallenge] = useState<Challenge | null>(null);
  const updateCardInfoMutation = useUpdateCardInfoMutation();

  const handleToken = async (token: RecurlyToken) => {
    setError(null);
    setSubmitting(true);

    await updateCardInfoMutation.mutateAsync(
      {
        initialToken: token.id,
      },
      {
        onSuccess: () => {
          props.onFinish?.();
        },
        onError: (error) => {
          if (error instanceof CardInfoChallengeError) {
            setChallenge({
              initialToken: token.id,
              challengeToken: error.token,
            });
          } else if (error instanceof CardInfoUpdateError) {
            setError(error);
          } else {
            handleApiError(error);
            setError(true);
          }
        },
        onSettled: () => {
          setSubmitting(false);
        },
      }
    );
  };

  const handleChallengeFinish = async (resultToken: RecurlyToken) => {
    if (challenge == null) {
      throw new Error('No challenge has been requested');
    }

    setError(null);
    setSubmitting(true);

    await updateCardInfoMutation.mutateAsync(
      {
        initialToken: challenge.initialToken,
        challengeToken: resultToken.id,
      },
      {
        onSuccess: () => {
          props.onFinish?.();
        },
        onError: (error) => {
          if (error instanceof CardInfoUpdateError) {
            setError(error);
          } else {
            handleApiError(error);
            setError(true);
          }

          setChallenge(null);
        },
        onSettled: () => {
          setChallenge(null);
          setSubmitting(false);
        },
      }
    );
  };

  const handleChallengeError = (error: RecurlyError) => {
    setError(error);
    setChallenge(null);
  };

  if (challenge != null) {
    const challengeElement = (
      <PaymentChallenge
        token={challenge.challengeToken}
        onFinish={handleChallengeFinish}
        onError={handleChallengeError}
      />
    );

    return props.renderChallenge(challengeElement);
  } else {
    return (
      <CardTokenForm onFinish={handleToken} formRef={props.formRef}>
        {(form) => {
          const errorMessage = getErrorMessage(error);

          return props.renderCardForm({
            isSubmitting: form.isSubmitting || submitting,
            errorMessage: form.errorMessage || errorMessage,
            recurlyHostedPage: form.recurlyHostedPage,
            recurlyLoadFailed: form.recurlyLoadFailed,
            fields: form.fields,
          });
        }}
      </CardTokenForm>
    );
  }
};

const getErrorMessage = (error: CardFormError | null): string | null => {
  if (error == null) {
    return null;
  } else if (error instanceof CardInfoUpdateError) {
    return error.message;
  } else if (error === true) {
    return 'Sorry, something went wrong. Please try again, or contact us if the problem persists.';
  } else {
    switch (error.code) {
      case 'validation':
        return 'Sorry, something is not right with your card details. Check the highlighted fields and try again.';
      case '3ds-auth-error':
        return 'Sorry, we could not verify your identity. Please try again, or contact us if the problem persists.';
      default:
        return 'Sorry, something went wrong. Please try again, or contact us if the problem persists.';
    }
  }
};
