import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { CarouselNavigation } from '@/features/common/components/carousel-navigation';
import { ITemplateCategory } from '@/features/test-library/types';
import { useCarousel } from '@/hooks/carousel';
import { Id } from '@/types/misc';

interface CategoriesProps {
  categories: ITemplateCategory[];
  selectedCategoryId?: Id;
  onCategoryClick: (categoryId: string) => void;
}

export function Categories({
  categories,
  selectedCategoryId,
  onCategoryClick,
}: CategoriesProps) {
  const {
    containerRef,
    contentRef,
    goNext,
    goPrev,
    canGoNext,
    canGoPrev,
    offset,
  } = useCarousel({ slideStep: 150 });
  const amplitude = getAmplitudeClient();

  return (
    <Container>
      <TabsContainer ref={containerRef}>
        <Tabs style={offset} ref={contentRef}>
          {categories?.map((category) => (
            <Tab
              key={category.id}
              active={category.id === selectedCategoryId}
              onClick={() => {
                onCategoryClick(category.id);
                amplitude?.logEvent('job templates/click category', {
                  categoryId: category.id,
                });
              }}
            >
              {category.name}
            </Tab>
          ))}
        </Tabs>
      </TabsContainer>

      <CategoriesCarouselNavigation>
        {(canGoPrev || canGoNext) && (
          <CarouselNavigation
            canGoToPrev={canGoPrev}
            canGoToNext={canGoNext}
            goPrev={goPrev}
            goNext={goNext}
          />
        )}
      </CategoriesCarouselNavigation>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 2px;
`;

const TabsContainer = styled.div`
  overflow: hidden;
  margin: 0 -10px 24px -10px;
  font-size: 12px;

  @media all and (max-width: 768px) {
    margin: 0 -10px 0px -10px;
  }
`;

const Tabs = styled.div`
  display: flex;
  position: relative;
  transition: 0.6s;
  transition-timing-function: ease-in-out;
  width: max-content;
  font-size: 12px;

  @media all and (max-width: 768px) {
    overflow-x: scroll;
    width: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
  }
`;

const Tab = styled.div<{ active: boolean }>`
  position: relative;
  margin-bottom: 8px;
  padding: 3px 10px;
  color: ${(props) => props.theme.colors.purple[100]};
  cursor: pointer;
  flex: none;
  margin-right: 4px;
  border-radius: 8px;

  ${(props) =>
    props.active &&
    css`
      font-weight: bold;
      position: relative;
      z-index: 5;

      ::after {
        position: absolute;
        content: ' ';
        width: calc(100% - 20px);
        left: 10px;
        bottom: -5px;
        border-bottom: 1px solid ${props.theme.colors.orange[100]};
      }
    `}
  &:hover {
    background-color: ${(props) => !props.active && props.theme.hover.onWhite};
  }
`;

const CategoriesCarouselNavigation = styled.div`
  margin: 0 38px 0 24px;
  position: relative;
  width: 48px;

  @media all and (max-width: 768px) {
    display: none;
  }
`;
