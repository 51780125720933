import { ITrial, useWorkspace } from '@/features/workspace';

/**
 *
 * @returns {
 * isTrialAvailable: return true if trail is available for active workspace
 * isTrialActive: return true if trail is active for active workspace
 * currentTrial: return data of an active trial
 * }
 */
export const useFreeTrial = (): {
  isTrialAvailable?: boolean;
  isTrialActive?: boolean;
  currentTrial?: ITrial;
  lastActiveTrial?: ITrial;
} => {
  const workspace = useWorkspace();

  return {
    isTrialAvailable: workspace?.trialState === 'available',
    isTrialActive: workspace?.trialState === 'active',
    currentTrial:
      !!workspace && workspace?.trialState === 'active'
        ? workspace.trials?.sort((a: ITrial, b: ITrial) => {
            const dateA = a.startedAt
              ? new Date(a.startedAt).getTime()
              : Infinity;
            const dateB = b.startedAt
              ? new Date(b.startedAt).getTime()
              : Infinity;

            return dateB - dateA;
          })?.[0]
        : undefined,
    lastActiveTrial:
      !!workspace && workspace?.trialState === 'expired'
        ? workspace.trials?.sort((a: ITrial, b: ITrial) => {
            const dateA = a.startedAt
              ? new Date(a.startedAt).getTime()
              : Infinity;
            const dateB = b.startedAt
              ? new Date(b.startedAt).getTime()
              : Infinity;

            return dateB - dateA;
          })?.[0]
        : undefined,
  };
};
