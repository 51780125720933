import api from '@/api/api';
import { BillingPlan } from '@/features/billing';
import { TId } from '@/features/common';
import { IWorkspace } from '@/features/workspace';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

export async function createWorkspace(params: {
  name: string;
}): Promise<IWorkspace> {
  const response = await api.post(`workspaces`, serialiseAPIData(params));

  return parseAPIData(response.data);
}

export async function updateWorkspace(params: {
  workspaceId: TId;
  workspace: IWorkspace;
}): Promise<IWorkspace> {
  const response = await api.put(
    `workspaces/${params.workspaceId}`,
    serialiseAPIData(params.workspace)
  );

  return parseAPIData(response.data);
}

export async function startWorkspaceFreeTrial(params: {
  workspaceId: TId;
  subscribePlan: BillingPlan;
}): Promise<void> {
  await api.post(
    `/workspaces/${params.workspaceId}/trial`,
    serialiseAPIData({ subscribePlan: params.subscribePlan })
  );
}

export async function finishWorkspaceFreeTrial(params: {
  workspaceId: TId;
  trialId: TId;
}): Promise<void> {
  await api.patch(
    `/workspaces/${params.workspaceId}/trial/${params.trialId}`,
    serialiseAPIData({ showDowngrade: false })
  );
}

export async function deleteWorkspace(params: { workspaceId: TId }) {
  await api.delete(`workspaces/${params.workspaceId}`);
}
