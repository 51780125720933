import React from 'react';
import styled from '@emotion/styled';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { ITemplate } from '@/features/test-library/types';

type TemplateProps = {
  template: ITemplate;
  onClick: () => void;
};

export function Template({ template, onClick }: TemplateProps) {
  const amplitude = getAmplitudeClient();

  return (
    <TemplateButton
      onClick={() => {
        onClick && onClick();
        amplitude?.logEvent('job templates/click on template', {
          name: template.primaryName,
          premium: template.isPremium,
        });
      }}
    >
      {template.primaryName}
    </TemplateButton>
  );
}

const TemplateButton = styled.button`
  background-color: ${(props) => props.theme.colors.purple[5]};
  color: ${(props) => props.theme.colors.purple[100]};
  border-radius: 8px;
  border: none;
  box-shadow: 0px 1px 4px 0px rgba(44, 19, 56, 0.1);
  padding: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  min-width: 322px;
  height: 66px;
  position: relative;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.purple[10]};
  }

  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
