import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DSButton,
  DSField,
  DSInput,
  LinkButton,
} from '@hundred5/design-system';

import { ClientError } from '@/errors';
import {
  BillingPlan,
  fetchCoupon as apiFetchCoupon,
  useSubscribeModal,
} from '@/features/billing';
import { CollapseTransition, Icon } from '@/features/common';
import { useApiError } from '@/hooks/api';
import { useAsync } from '@/hooks/async';
import { useWorkspaceId } from '@/hooks/router';

import { isValidCoupon } from './coupon-form.utils';

export function CouponForm({ plan }: { plan: BillingPlan | null }) {
  const handleApiError = useApiError();
  const [fetchCoupon, { loading }] = useAsync(apiFetchCoupon);
  const workspaceId = useWorkspaceId();
  const { coupon, onCouponActivated, onCouponCanceled } = useSubscribeModal();

  const [opened, setOpened] = useState(!!coupon);
  const [error, setError] = useState('');
  const [inputValue, setInputValue] = useState('');

  const handleSubmitCouponForm = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      fetchCoupon(inputValue, plan, workspaceId)
        .then((responseCoupon) => {
          if (responseCoupon && plan) {
            if (isValidCoupon(responseCoupon, plan)) {
              onCouponActivated(responseCoupon);
              setInputValue('');
            }
          }
        })
        .catch((err) => {
          if (err instanceof ClientError && err.type === 'CouponRequired') {
            setError('Invalid promo code');
          } else if (
            err instanceof ClientError &&
            err.type === 'CouponNotAppliesForPlan'
          ) {
            setError('Sorry, this coupon is not applicable to this plan.');
          } else {
            handleApiError(error);
          }
        });
    },
    [
      fetchCoupon,
      plan,
      onCouponActivated,
      inputValue,
      error,
      handleApiError,
      workspaceId,
    ]
  );

  return (
    <CouponFormContainer>
      {coupon ? (
        <CouponActivated>
          {coupon.name}
          <Icon
            icon={regular('xmark')}
            color="purple-100"
            fontSize="10px"
            onClick={() => {
              setOpened(false);
              onCouponCanceled();
            }}
          />
        </CouponActivated>
      ) : (
        <>
          <Header opened={opened}>
            <div onClick={() => setOpened((prevState) => !prevState)}>
              Got a promotion code?{' '}
              <Icon
                icon={regular('angle-up')}
                color="purple-100"
                fontSize="10px"
              />
            </div>
            {coupon && <CancelButton>Cancel coupon</CancelButton>}
          </Header>
          <CollapseTransition in={opened}>
            <Form onSubmit={handleSubmitCouponForm}>
              <PromotionCodeField for="discount-code" label={''} error={error}>
                <DSInput
                  id="discount-code"
                  type="text"
                  placeholder="Add your promo code"
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    setError('');
                  }}
                  error={!!error}
                />
              </PromotionCodeField>

              <DSButton
                type="submit"
                disabled={loading || !inputValue}
                variant="primary"
              >
                {loading ? 'Adding...' : 'Add'}
              </DSButton>
            </Form>
          </CollapseTransition>
        </>
      )}
    </CouponFormContainer>
  );
}

const CouponFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

const Header = styled.div<{ opened: boolean }>`
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  line-height: 150%;
  width: 316px;
  cursor: pointer;

  svg {
    transform: ${(props) => !props.opened && 'rotate(180deg)'};
  }
`;

const CancelButton = styled(LinkButton)`
  color: ${(props) => props.theme.colors.orange[100]};
`;

const Form = styled.form`
  display: flex;
  align-items: flex-start;
  padding-top: 8px;
  flex-wrap: wrap;
`;

const PromotionCodeField = styled(DSField)`
  width: 280px;
  margin-right: 8px;

  span {
    order: 3;
    margin-top: 4px;
    margin-bottom: 0;
  }
`;

const CouponActivated = styled.div`
  position: absolute;
  top: -32px;
  color: ${(props) => props.theme.typography.colorPrimary};
  background: ${(props) => props.theme.colors.yellow[50]};
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  padding: 0 8px;
  border-radius: 8px;
  gap: 8px;
`;
