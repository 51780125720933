import { useCallback } from 'react';
import store from 'store';

import { userHasConsentedToPreferences } from '@/features/gdpr/utils/permissions';

/**
 * A hook to handle data in local storage.
 * @param storageKey - the key to use for the data in local storage
 * @returns [data, setData] - the data from local storage and a function to set the data
 */
export function useStoreData<T>(
  storageKey: string
): [T | null, (params: T) => void] {
  const setStoreData = useCallback(
    (params: T) => {
      if (storageKey && userHasConsentedToPreferences()) {
        store.set(storageKey, params);
      }
    },
    [storageKey]
  );

  if (!storageKey) {
    throw new Error(`useStoreData should be provided with a storageKey.`);
  }

  return [store.get(storageKey, null), setStoreData];
}
