import React from 'react';
import styled from '@emotion/styled';

import { CardForm, CardFormLayout } from '@/features/billing';
import { Alert } from '@/features/common';
import { useWorkspaceId } from '@/hooks/router';

export function BillingInfoForm(props: { onFinish: () => void; formRef: any }) {
  const workspaceId = useWorkspaceId();

  return (
    <CardForm
      workspaceId={workspaceId}
      onFinish={props.onFinish}
      formRef={props.formRef}
      renderCardForm={(cardForm) => (
        <BillingInfoContainer data-recording-ignore="mask">
          {cardForm.errorMessage ? (
            <Alert type="error">{cardForm.errorMessage}</Alert>
          ) : null}

          <CardFormLayout
            fields={cardForm.fields}
            recurlyHostedPage={cardForm.recurlyHostedPage}
            recurlyLoadFailed={cardForm.recurlyLoadFailed}
          />
        </BillingInfoContainer>
      )}
      renderChallenge={(challenge) => challenge}
    />
  );
}

const BillingInfoContainer = styled.div`
  display: grid;
  gap: 10px;
`;
