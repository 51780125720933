import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { BillingPlan, useFreeTrial } from '@/features/billing';
import { useMeQuery } from '@/features/me';
import {
  useFinishWorkspaceFreeTrialMutation,
  WorkspaceSettingsBillingPlans,
} from '@/features/workspace';
import { useHistory, useWorkspaceIdOrNull } from '@/hooks/router';

import { DowngradeFree, FreeTrial, Upgrade } from './ui';

export const Downgrade = () => {
  const history = useHistory();
  const workspaceId = useWorkspaceIdOrNull();
  const meQuery = useMeQuery();
  const finishDowngradeTrial = useFinishWorkspaceFreeTrialMutation();
  const { lastActiveTrial } = useFreeTrial();

  const downgradeFreeTrialCompleted = meQuery.data?.workspaces?.find(
    (workspace) => workspace.id === workspaceId
  )?.trialState;

  const [downgradeState, setDowngradeState] = useState<string>('trial');
  const [planSelected, setPlanSelected] = useState<BillingPlan>();

  const onContinueDowngradeFree = async () => {
    if (workspaceId && lastActiveTrial) {
      await finishDowngradeTrial.mutate(
        {
          workspaceId: workspaceId,
          trialId: lastActiveTrial.id,
        },
        {
          onSuccess: () => history.push(`/admin/${workspaceId}/openings`),
        }
      );
    }
  };

  useEffect(() => {
    if (downgradeFreeTrialCompleted === 'expired') {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => document.body.classList.remove('no-scroll');
  }, [downgradeFreeTrialCompleted]);

  if (downgradeFreeTrialCompleted !== 'expired') {
    return null;
  }

  return (
    <FullPage>
      <Container>
        {downgradeState === 'trial' && (
          <FreeTrial onContinue={() => setDowngradeState('billing')} />
        )}

        {downgradeState === 'billing' && (
          <BillingWrapper>
            <WorkspaceSettingsBillingPlans
              isTrialVariant={true}
              onSubscribe={(billingPlan, selectedPeriod) => {
                setDowngradeState(
                  billingPlan?.plan === 'free' ? 'free' : 'upgrade'
                );
                setPlanSelected(selectedPeriod);
              }}
            />
          </BillingWrapper>
        )}

        {downgradeState === 'free' && (
          <DowngradeFree
            onGoBack={() => setDowngradeState('billing')}
            onContinue={onContinueDowngradeFree}
          />
        )}

        {downgradeState === 'upgrade' && (
          <Upgrade
            plan={planSelected}
            onGoBack={() => setDowngradeState('billing')}
          />
        )}
      </Container>
    </FullPage>
  );
};

const FullPage = styled.div`
  position: fixed;
  inset: 0;
  z-index: 9999;
  /* Purple 100 with 50% opacity*/
  background-color: rgba(44, 19, 56, 0.5);
  padding: 24px;
  overflow-y: auto;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1185px;
  min-height: 100%;
  margin: auto;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.white};
  padding: 36px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 36px;
`;

const BillingWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
