import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { trackIntercomEvent } from '@/features/intercom';
import { INotification } from '@/features/notifications';

export function logNewNotification(newNotification: INotification) {
  const amplitude = getAmplitudeClient();
  amplitude?.logEvent('show notification', {
    kind: newNotification.content.kind,
    type: newNotification.content.type,
    meta: newNotification.content.meta,
  });

  if (newNotification.content.kind === 'failure') {
    trackIntercomEvent('failure_notification_received', {
      type: newNotification.content.type,
      meta: newNotification.content.meta,
    });
  }
}
