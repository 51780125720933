import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Illustration = styled.img<{ centered?: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: ${(props) => props.theme?.colors?.peach[100]};

  ${(props) =>
    props.centered
      ? css`
          padding: 24px clamp(44px, 22%, 125px);
        `
      : css`
          padding: 24px 0 24px clamp(96px, 30%, 148px);
        `}

  @media all and (max-width: 768px) {
    display: none;
  }
`;
