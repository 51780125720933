import { PlanLevel, PlanName } from '@/hooks/planLimits';

import { BillingPlan } from '../types';

export interface IBillingPlansDescription {
  plan: PlanName; // 'free' | 'starter' | 'premium'
  planLevel: PlanLevel; // 'free' | 'starter' | 'premium'
  planCode: {
    monthly: BillingPlan; // '2024-09-free-ats' | '2024-09-starter-1m-ats' | '2024-09-premium-1m-ats'
    annual: BillingPlan; // '2024-09-free-ats' | '2024-09-starter-12m-ats' | '2024-09-premium-12m-ats'
  };
  description: string;
  price: {
    monthly: { perMonth: number };
    annual: { perMonth: number };
  };
  limits: {
    limit: string;
    info?: string;
  }[];
}

interface IBillingPlansConfig {
  plans: IBillingPlansDescription[];
  yearlyPlansDiscountPercentage: number;
}

export const billingPlansDescriptions: IBillingPlansConfig = {
  yearlyPlansDiscountPercentage: 20,
  plans: [
    {
      plan: 'free',
      planLevel: 'free',
      planCode: { monthly: '2024-09-free-ats', annual: '2024-09-free-ats' },
      description:
        'Basic ATS and assessment features ideal for small businesses',
      price: {
        monthly: { perMonth: 0 },
        annual: { perMonth: 0 },
      },
      limits: [
        { limit: '1 active job opening' },
        { limit: '1 skills test' },
        {
          limit: 'Candidates up to 30 days',
          info: 'Access candidate data for the past 30 days',
        },
      ],
    },
    {
      plan: 'starter',
      planLevel: 'starter',
      planCode: {
        monthly: '2024-09-starter-1m-ats',
        annual: '2024-09-starter-12m-ats',
      },
      description:
        'Customizable skills-first, full-cycle hiring software for growing teams',
      price: {
        monthly: { perMonth: 239 },
        annual: { perMonth: 199 },
      },
      limits: [
        { limit: '3 active job openings' },
        { limit: 'Unlimited skills tests' },
        { limit: 'Unlimited candidates' },
      ],
    },
    {
      plan: 'premium',
      planLevel: 'premium',
      planCode: {
        monthly: '2024-09-premium-1m-ats',
        annual: '2024-09-premium-12m-ats',
      },
      description: 'Great for large teams with ongoing hiring needs',
      price: {
        monthly: { perMonth: 419 },
        annual: { perMonth: 349 },
      },
      limits: [
        { limit: 'Unlimited job openings' },
        { limit: 'Unlimited skills tests' },
        { limit: 'Unlimited candidates' },
      ],
    },
  ],
};

export const billingPlans: Partial<
  Record<
    BillingPlan,
    { name: string; type: 'Annually' | 'Monthly'; price: number }
  >
> = {
  '2024-09-starter-1m-ats': {
    name: 'Starter',
    type: 'Monthly',
    price: 239,
  },
  '2024-09-starter-12m-ats': {
    name: 'Starter',
    type: 'Annually',
    price: 199,
  },
  '2024-09-premium-1m-ats': {
    name: 'Premium',
    type: 'Monthly',
    price: 419,
  },
  '2024-09-premium-12m-ats': {
    name: 'Premium',
    type: 'Annually',
    price: 349,
  },
};
