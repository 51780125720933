import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSTestResultPage } from '@hundred5/design-system';
import { useFormikContext } from 'formik';

import { FormikCheckboxField, FormikInputField, Icon } from '@/features/common';
import { useGenericPricingUpsellModalContext } from '@/features/upsell';
import { usePlanLimits } from '@/hooks/planLimits';

import { TResultPageSettingsForm } from '../result-page-settings-form.types';

export function Redirect() {
  const canAccessFeature = usePlanLimits();
  const { openPricingUpsell } = useGenericPricingUpsellModalContext();
  const form = useFormikContext<TResultPageSettingsForm>();

  return (
    <DSTestResultPage.DataSection title="Redirect URL">
      <FormikCheckboxField
        name="redirectUrlEnabled"
        label={
          <Label>
            Redirect candidates after they submit the test
            <Icon
              icon={regular('info-circle')}
              color="purple-60"
              fontSize={14}
              data-rh-at="right"
              data-rh="Candidates won’t see the results page"
            />
          </Label>
        }
        onChange={(e) => {
          // we have users that have this feature on, but are on lower plan, so they should always be able to unselect that
          if (!e.target.checked || canAccessFeature('candidate_redirect_url')) {
            form.setFieldValue('redirectUrlEnabled', e.target.checked);
            form.setFieldValue('redirectUrl', '');
          } else {
            openPricingUpsell('generic', 'premium');
          }
        }}
      />
      {form.values.redirectUrlEnabled && (
        <InputField
          type="url"
          name="redirectUrl"
          label="URL"
          required
          placeholder="https://example.com/hire-redirect"
        />
      )}
    </DSTestResultPage.DataSection>
  );
}

const InputField = styled(FormikInputField)`
  margin-left: 24px;
`;

const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`;
