import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  DSButton,
  DSField,
  DSStyledContent,
  DSTestResultPage,
} from '@hundred5/design-system';
import { CompanyLogo, Header } from '@togglhire/candidate-components';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { Markdown, PromptIfDirty, TId } from '@/features/common';
import {
  createEditorState,
  CustomTextEditor,
  getEditorMarkdown,
} from '@/features/common/components/custom-text-editor';
import { trackIntercomEvent } from '@/features/intercom';
import { useJobOpeningQuery } from '@/features/job-opening';
import { useJobOpeningPageEventData } from '@/features/job-opening/utils/job-opening-page-analytics';
import { useNotifications } from '@/features/notifications';
import { useJobOpeningPermission } from '@/features/permissions';
import {
  getTestTypeName,
  TScreenType,
  useTestQuery,
  useUpdateTestMutation,
} from '@/features/test';
import { useWorkspaceQuery } from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';

import { SYSTEM_CONTACT_FIELDS } from './result-page-settings-form.const';
import { TResultPageSettingsForm } from './result-page-settings-form.types';
import { replaceScreenTemplateTags } from './result-page-settings-form.utils';
import {
  Attachments,
  Chart,
  ContactInfo,
  Details,
  Experience,
  FormDescription,
  FormTitle,
  PoweredBy,
  Redirect,
  RedirectBlock,
} from './ui';

interface ResultPageSettingsFormProps {
  testId: TId;
  screenType: TScreenType;
}

const validationSchema = object().shape({
  redirectUrl: string().when('redirectUrlEnabled', {
    is: (redirectUrlEnabled) => !!redirectUrlEnabled,
    then: (schema) => schema.trim().required('This field is mandatory'),
  }),
  screenContent: string().max(
    6000,
    'Character limit reached. Please try shortening the page content!'
  ),
});

export function ResultPageSettingsForm({
  testId,
  screenType,
}: ResultPageSettingsFormProps) {
  const workspaceId = useWorkspaceId();
  const { addNotification } = useNotifications();
  const eventData = useJobOpeningPageEventData();
  const canUpdateJobOpening = !!useJobOpeningPermission()(
    'job opening',
    'update'
  );
  const [editing, setEditing] = useState(false);
  const [editorState, setEditorState] = useState(createEditorState(''));
  const amplitude = getAmplitudeClient();
  const workspaceQuery = useWorkspaceQuery({ workspaceId });
  const jobOpeningQuery = useJobOpeningQuery();
  const testQuery = useTestQuery({ testId });
  const updateTestMutation = useUpdateTestMutation();

  if (
    !workspaceQuery.isSuccess ||
    !jobOpeningQuery.isSuccess ||
    !testQuery.isSuccess
  ) {
    return null;
  }

  const testType = testQuery.data.type;
  const testScreen = testQuery.data.screens[screenType];
  const enabledAttachments = jobOpeningQuery.data.isAttachmentsEnabled
    ? jobOpeningQuery.data.attachments?.filter((item) => item.isEnabled)
    : [];

  const initialValues: TResultPageSettingsForm = {
    screenContent: testScreen?.content ?? '',
    scoreChart: testScreen?.settings['scoreChart'] ?? testType === 'quiz',
    userExperience: testScreen?.settings['userExperience'] ?? true,
    redirectUrlEnabled: testScreen?.settings['redirectUrlEnabled'] ?? false,
    redirectUrl: testScreen?.settings['redirectUrl'] ?? '',
    systemContactFields:
      testScreen?.settings['systemContactFields'] ?? SYSTEM_CONTACT_FIELDS,
    userContactFields: testScreen?.settings['userContactFields'] ?? [],
  };

  const handleEdit = () => {
    setEditing(true);
    setEditorState(createEditorState(initialValues.screenContent ?? ''));
  };

  const handleCancel = (resetForm: () => void) => {
    resetForm();
    setEditing(false);
  };

  const handleSave = async (values: TResultPageSettingsForm) => {
    const { screenContent, ...settings } = values;

    const updatedTest = {
      screens: {
        ...testQuery.data.screens,
        [screenType]: {
          settings: {
            ...testQuery.data.screens[screenType]?.settings,
            ...settings,
          },
          content: screenContent,
        },
      },
    };

    await updateTestMutation.mutateAsync({ testId, test: updatedTest });

    amplitude?.logEvent('job setup/save test result page', {
      ...eventData,
      testType,
    });
    trackIntercomEvent('edited_job_results');

    addNotification({ type: 'saved' });
    setEditing(false);
  };

  return (
    <Formik<TResultPageSettingsForm>
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={handleSave}
    >
      {(form) => (
        <Form>
          <PromptIfDirty />
          <SettingsFormHeader>
            <Information>
              <FormTitle screenType={screenType} />
              <FormDescription screenType={screenType} />
            </Information>
            <ActionButtons>
              {editing ? (
                <>
                  <DSButton
                    variant="secondary"
                    onClick={() => handleCancel(form.resetForm)}
                    disabled={form.isSubmitting}
                  >
                    Cancel
                  </DSButton>
                  <DSButton
                    type="submit"
                    disabled={form.isSubmitting || !form.dirty}
                  >
                    Save
                  </DSButton>
                </>
              ) : (
                <DSButton
                  variant="secondary"
                  onClick={handleEdit}
                  disabled={!canUpdateJobOpening}
                  data-rh={
                    !canUpdateJobOpening
                      ? 'Only admins can edit job description'
                      : null
                  }
                >
                  Edit
                </DSButton>
              )}
            </ActionButtons>
          </SettingsFormHeader>

          {!editing && form.values.redirectUrlEnabled ? (
            <RedirectBlock redirectUrl={form.values.redirectUrl} />
          ) : (
            <DSTestResultPage embedded>
              <DSTestResultPage.Header>
                <Header
                  title={jobOpeningQuery.data.name}
                  subtitle={getTestTypeName(testType)}
                  logo={
                    <CompanyLogo
                      name={workspaceQuery.data?.name!}
                      logoUrl={workspaceQuery.data?.logoUrl}
                    />
                  }
                />
              </DSTestResultPage.Header>

              {testType === 'quiz' && (
                <Chart screenType={screenType} editing={editing} />
              )}

              {testType === 'video' && (
                <DSTestResultPage.Media embedded>
                  <DSTestResultPage.VideoIllustration />
                  <PoweredBy displayOn="desktop" />
                </DSTestResultPage.Media>
              )}

              {testType === 'homework' && (
                <DSTestResultPage.Media embedded>
                  <DSTestResultPage.HomeworkIllustration />
                  <PoweredBy displayOn="desktop" />
                </DSTestResultPage.Media>
              )}

              <DSTestResultPage.Main>
                <DSStyledContent>
                  {editing ? (
                    <DSField
                      for="screenContent"
                      error={
                        form.touched.screenContent &&
                        !!form.errors.screenContent ? (
                          <>{form.errors.screenContent}</>
                        ) : null
                      }
                    >
                      <CustomTextEditor
                        minHeight="160px"
                        state={editorState}
                        onChange={(state) => {
                          form.setFieldValue(
                            'screenContent',
                            getEditorMarkdown(state)
                          );
                          setEditorState(state);
                        }}
                        error={
                          form.touched.screenContent &&
                          !!form.errors.screenContent
                        }
                      />
                    </DSField>
                  ) : (
                    <Markdown>
                      {replaceScreenTemplateTags(
                        testScreen?.content || '',
                        screenType
                      )}
                    </Markdown>
                  )}
                </DSStyledContent>

                <DSTestResultPage.DataContainer>
                  <Details editing={editing} />

                  {!editing && <Attachments attachments={enabledAttachments} />}

                  {editing && <Experience />}

                  {editing && <Redirect />}

                  {!editing && <SendButton disabled>Send</SendButton>}
                </DSTestResultPage.DataContainer>

                <DSTestResultPage.Info>
                  <ContactInfo
                    name={workspaceQuery.data?.name!}
                    email={jobOpeningQuery.data.contactEmail}
                  />
                </DSTestResultPage.Info>

                <PoweredBy displayOn="mobile" />
              </DSTestResultPage.Main>
            </DSTestResultPage>
          )}
        </Form>
      )}
    </Formik>
  );
}

const SettingsFormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
`;

const Information = styled.div`
  display: grid;
  gap: 4px;
  align-self: center;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 4px;
`;

const SendButton = styled(DSButton)`
  width: 100%;

  @media (min-width: 768px) {
    float: right;
    width: auto;
  }
`;
