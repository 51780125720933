import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import { FormikInputField, FormikTextAreaField } from '@/features/common';
import { useNotifications } from '@/features/notifications';
import { useWorkspacePermission } from '@/features/permissions';
import { useGenericPricingUpsellModalContext } from '@/features/upsell';
import { usePlanLimits } from '@/hooks/planLimits';
import { useWorkspaceId } from '@/hooks/router';

import { useRequestSkillOrTemplateMutation } from '../../queries/request';

const validationSchema = object().shape({
  name: string().required("This can't be blank!"),
  notes: string().required("This can't be blank!"),
});

type RequestTemplateModalProps = {
  open: boolean;
  onClose: () => void;
  createCustomTest: () => void;
};

export function RequestTemplateModal({
  open,
  onClose,
  createCustomTest,
}: RequestTemplateModalProps) {
  const workspaceId = useWorkspaceId();
  const { addNotification } = useNotifications();
  const requestSkillOrTemplateMutation = useRequestSkillOrTemplateMutation();
  const canCreateJobOpening = useWorkspacePermission()(
    'job openings',
    'create'
  );
  const canAccessFeature = usePlanLimits();
  const canCreateCustomTest = canAccessFeature('create_custom_test');
  const { openPricingUpsell } = useGenericPricingUpsellModalContext();

  const handleSubmit = async (values) => {
    await requestSkillOrTemplateMutation.mutateAsync(
      {
        type: 'template',
        name: values.name,
        notes: values.notes,
        workspace_id: Number(workspaceId),
      },
      {
        onSuccess: () => {
          addNotification({ type: 'position_request_sent' });
          onClose();
        },
      }
    );
  };

  return (
    <DSModal open={open} onClose={onClose} contentStyle={{ width: 610 }}>
      <Header>
        Request a template
        <DSModal.CloseButton fixed small onClick={onClose} />
      </Header>

      <DSModal.Separator />

      <Formik<{ name: string; notes: string }>
        initialValues={{
          name: '',
          notes: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ dirty }) => (
          <Form>
            <Content>
              <FormikInputField
                label="Test title"
                name="name"
                placeholder="E.g. iOS Developer"
                required
              />
              <FormikTextAreaField
                label="Template skills and description"
                name="notes"
                placeholder="E.g. iOS, Swift, Cocoa Pods, building iOS apps"
                required
                inputHeight="144px"
              />
            </Content>
            <Footer float="right">
              {canCreateJobOpening && (
                <DSButton
                  variant="secondary"
                  onClick={() => {
                    canCreateCustomTest
                      ? createCustomTest
                      : openPricingUpsell('unlimitedSkillsTests');
                  }}
                >
                  Create a custom test
                </DSButton>
              )}
              <DSButton type="submit" disabled={!dirty}>
                Request template
              </DSButton>
            </Footer>
          </Form>
        )}
      </Formik>
    </DSModal>
  );
}

const Header = styled.div`
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 16px;
`;

const Content = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

const Footer = styled(DSModal.Footer)`
  gap: 8px;
  padding-top: 60px;
`;
