import { useCallback, useMemo } from 'react';
import { debounce } from 'lodash';

import {
  AuthorizationError,
  ClientError,
  MultiClientError,
  NetworkError,
  ReCaptchaError,
  ServerError,
} from '@/errors';
import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { TNotificationInput, useNotifications } from '@/features/notifications';

export const useApiError = () => {
  const { addNotification } = useNotifications();
  const amplitude = getAmplitudeClient();
  const debounceNotification = useMemo(
    () =>
      debounce((notification: TNotificationInput) => {
        addNotification(notification);
      }, 1000),
    [addNotification]
  );

  return useCallback(
    (error) => {
      if (error instanceof ServerError) {
        amplitude?.logEvent('errors/server error');
        debounceNotification({
          type: 'unexpected',
          meta: {
            errorType: error.type,
            errorId: error.errorId,
            errorMessage: error.message,
          },
        });
      } else if (error instanceof NetworkError) {
        amplitude?.logEvent('errors/network error');
        debounceNotification({ type: 'network_error' });
      } else if (error instanceof AuthorizationError) {
        amplitude?.logEvent('errors/authorization error');
      } else if (error instanceof ReCaptchaError) {
        debounceNotification({ type: 'recaptcha_error' });
      } else if (error instanceof MultiClientError) {
        amplitude?.logEvent('errors/multi client error', {
          errors: error.errors,
          errorId: error.errorId,
        });
        debounceNotification({
          type: 'unexpected',
          meta: {
            errorType: error.name,
            errorId: error.errorId,
            errorMessage: error.errors,
          },
        });
      } else if (error instanceof ClientError) {
        amplitude?.logEvent('errors/client error', {
          type: error.type,
          error: error.message,
          errorId: error.errorId,
        });

        switch (error.type) {
          case 'EmailVerificationRequired': // we redirect the user to the verify email page
          case 'ForbiddenInSupportMode': {
            // do not show notification
            break;
          }
          case 'bad_request': {
            debounceNotification({ type: 'bad_request' });
            break;
          }
          case 'forbidden':
          case 'Forbidden': {
            debounceNotification({ type: 'forbidden' });
            break;
          }
          case 'invalid':
          case 'InvalidInput': {
            debounceNotification({ type: 'invalid' });
            break;
          }
          case 'not_found':
          case 'NotFound': {
            debounceNotification({ type: 'not_found' });
            break;
          }

          case 'rate_limited': {
            debounceNotification({ type: 'rate_limited' });
            break;
          }

          default: {
            debounceNotification({
              type: 'unexpected',
              meta: {
                errorType: error.type,
                errorId: error.errorId,
                errorMessage: error.message,
              },
            });
          }
        }
      }
    },
    [amplitude, debounceNotification]
  );
};
