import { useEffect, useMemo } from 'react';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { useJobOpeningQuery } from '@/features/job-opening';

export const useJobOpeningPageEventData = () => {
  const { data: jobOpening, isLoading } = useJobOpeningQuery();
  return useMemo(() => {
    if (isLoading || !jobOpening) {
      return null;
    } else {
      return {
        'workspace id': jobOpening.workspaceId,
        'job opening id': jobOpening.id,
      };
    }
  }, [jobOpening, isLoading]);
};

export const useJobOpeningPageAnalytics = (pageName: string) => {
  const data = useJobOpeningPageEventData();
  const amplitude = getAmplitudeClient();

  useEffect(() => {
    if (data == null) return;
    amplitude?.logEvent(`page view/open ${pageName}`, data);
  }, [amplitude, data, pageName]);
};
