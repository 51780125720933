import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
  billingPlansDescriptions,
  BillingPlan as BillingPlanType,
  CancelSubscribeModal,
  ECancelSubscribeStep,
  IBillingPlansDescription,
  useFreeTrial,
  useSubscribeModal,
  useSubscriptionQuery,
} from '@/features/billing';
import { Icon } from '@/features/common';
import { getPlanPeriod } from '@/hooks/planLimits';
import { useWorkspaceId } from '@/hooks/router';

import { useWorkspaceQuery } from '../../queries';

import { BillingPlan, PeriodSelector } from './ui';

interface IWorkspaceSettingsBillingPlansProps {
  isTrialVariant?: boolean;
  onSubscribe?: (plan, selectedPeriod) => void;
}

export const WorkspaceSettingsBillingPlans = ({
  isTrialVariant,

  onSubscribe,
}: IWorkspaceSettingsBillingPlansProps) => {
  const workspaceId = useWorkspaceId();
  const [selectedPeriod, setSelectedPeriod] = useState<'monthly' | 'annual'>(
    'annual'
  );
  const [activePlan, setActivePlan] =
    useState<BillingPlanType>('2024-09-free-ats');
  const [cancelSubscribeStep, setCancelSubscribeStep] = useState<
    ECancelSubscribeStep | undefined
  >();
  const [billingPlanDowngrade, setBillingPlanDowngrade] = useState<
    IBillingPlansDescription | undefined
  >();

  const plans = billingPlansDescriptions.plans;

  const { data: billingAccount } = useSubscriptionQuery();
  const { openSubscribeModal } = useSubscribeModal();
  const workspaceQuery = useWorkspaceQuery({ workspaceId });
  const { isTrialActive } = useFreeTrial();

  const currentPlan = billingAccount
    ? billingAccount.subscription.currentPlan
    : null;
  const subscriptionActive = billingAccount
    ? billingAccount.subscription.state === 'active'
    : false;

  useEffect(() => {
    if (isTrialActive) {
      setSelectedPeriod('annual');
      setActivePlan('2024-09-free-ats');
    } else {
      if (currentPlan) {
        setSelectedPeriod(getPlanPeriod(currentPlan));
        setActivePlan(currentPlan);
      } else if (workspaceQuery.data?.subscriptionPlan) {
        setSelectedPeriod(getPlanPeriod(workspaceQuery.data.subscriptionPlan));
        setActivePlan(workspaceQuery.data.subscriptionPlan);
      }
    }
  }, [currentPlan, workspaceQuery.data, isTrialActive]);

  return (
    <BillingPlansContainer>
      <PeriodSelectorWrapper>
        Choose your billing
        <PeriodSelector
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
        />
      </PeriodSelectorWrapper>
      <PlansContainer>
        {plans.map((billingPlan) => (
          <BillingPlan
            isTrialVariant={isTrialVariant}
            planDescription={billingPlan}
            currentPlan={activePlan}
            selectedPeriod={selectedPeriod}
            downgradeDate={
              !subscriptionActive
                ? billingAccount?.subscription.currentPeriodEndsAt
                : undefined
            }
            subscribe={() => {
              if (!!onSubscribe) {
                onSubscribe(
                  billingPlan,
                  selectedPeriod === 'monthly'
                    ? billingPlan.planCode.monthly
                    : billingPlan.planCode.annual
                );
              } else {
                openSubscribeModal(
                  selectedPeriod === 'monthly'
                    ? billingPlan.planCode.monthly
                    : billingPlan.planCode.annual
                );
              }
            }}
            cancelSubsctiption={() => {
              if (!!onSubscribe) {
                onSubscribe(
                  billingPlan,
                  selectedPeriod === 'monthly'
                    ? billingPlan.planCode.monthly
                    : billingPlan.planCode.annual
                );
              } else {
                setBillingPlanDowngrade(billingPlan);
                setCancelSubscribeStep(ECancelSubscribeStep.Initial);
              }
            }}
            key={billingPlan.planCode.monthly}
          />
        ))}
      </PlansContainer>
      <Comparison>
        There's more!{' '}
        <a
          href="https://toggl.com/hire/pricing"
          target="_blank"
          rel="noreferrer"
        >
          See full plan comparison
        </a>{' '}
        <Icon icon={regular('arrow-up-right')} fontSize="16px" />
      </Comparison>
      <CancelSubscribeModal
        selectedBillingPlan={billingPlanDowngrade}
        currentStep={cancelSubscribeStep}
        setCurrentStep={setCancelSubscribeStep}
      />
    </BillingPlansContainer>
  );
};

const BillingPlansContainer = styled.div`
  container-name: billing-plans-container;
  container-type: inline-size;
  margin-bottom: 36px;
`;

const PeriodSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 24px 0 48px;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
`;

const PlansContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: center;
  gap: 16px;
  padding: 12px 4px;
  overflow-x: auto;

  @container billing-plans-container (width < 820px) {
    justify-content: start;
  }
`;

const Comparison = styled.div`
  padding-top: 16px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.typography.colorPrimary};

  a {
    color: ${(props) => props.theme.typography.colorPrimary};
    text-decoration: underline;
    font-weight: 500;
  }
`;
