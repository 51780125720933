import React, { createContext, useContext, useState } from 'react';

type TWizardContext = {
  stepCount: number;
  currentStep: number;
  goBack: () => void;
  goForward: () => void;
};

const WizardContext = createContext<TWizardContext>({
  stepCount: 0,
  currentStep: 0,
  goBack: () => {},
  goForward: () => {},
});

export function useWizard<T>() {
  return useContext<TWizardContext>(WizardContext);
}

export function WizardProvider<T>({
  stepCount,
  children,
}: {
  stepCount: number;
  children: React.ReactNode;
}) {
  const [currentStep, setCurrentStep] = useState(0);

  const goBack = () => {
    if (currentStep === 0) {
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const goForward = () => {
    if (currentStep === stepCount) {
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  return (
    <WizardContext.Provider
      value={{
        stepCount,
        currentStep,
        goBack,
        goForward,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
}

function WizardProviderStep({
  index,
  children,
}: {
  index: number;
  children: React.ReactNode;
}) {
  const { currentStep } = useWizard();

  if (currentStep === index) {
    return <>{children}</>;
  }

  return null;
}

WizardProvider.Step = WizardProviderStep;
