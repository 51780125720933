import { BillingPlan } from '@/features/billing';
import { useGetPlanRequired } from '@/hooks/planLimits';

import attachmentsPreview from '../components/icons/attachments_preview.svg';
import { PreviewFeatureModalType } from '../types';

export const titles: Record<PreviewFeatureModalType, string> = {
  custom_test: 'Custom test',
  attachments: 'Attachments',
};

export const subTitles: Record<PreviewFeatureModalType, string> = {
  custom_test:
    'Create a test from scratch or mix and match your own questions with our test library',
  attachments:
    'Enable up to 10 attachments to collect additional files, such as candidate’s work sample or portfolio.',
};

export const pros: Record<PreviewFeatureModalType, string[]> = {
  custom_test: [
    'Be in charge of the process',
    'Create your own questions',
    'Choose from 15 000+ questions ',
  ],
  attachments: [
    'Shortlist candidates faster',
    'Keep everything about a candidate in one place',
    'Boost efficiency',
  ],
};

export const upsells: Record<PreviewFeatureModalType, string> = {
  custom_test:
    'You can create a custom test on a free plan, just need to upgrade before sharing it with candidates.',
  attachments: 'Unlock to automate your hiring process effortlessly.',
};

export const previewIcons: Partial<Record<PreviewFeatureModalType, string>> = {
  attachments: attachmentsPreview,
};

export const useRequiredPlans: () => Record<
  PreviewFeatureModalType,
  BillingPlan
> = () => {
  const candidateEmailsRequiredPlan =
    useGetPlanRequired('candidate_history', 'monthly') || 'premium-1m-v2-2023';
  const attachmentsRequiredPlan =
    useGetPlanRequired('candidate_attachments', 'monthly') ||
    'premium-1m-v2-2023';
  const customTestRequiredPlan =
    useGetPlanRequired('create_custom_test', 'monthly') || 'premium-1m-v2-2023';

  return {
    candidate_emails: candidateEmailsRequiredPlan,
    custom_test: customTestRequiredPlan,
    attachments: attachmentsRequiredPlan,
  };
};
