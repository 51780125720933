import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { mixed, object } from 'yup';

import { FormikRadioField, FormikSelectField } from '@/features/common';
import {
  GreenhouseIcon,
  IATSIntegration,
  IJobOpeningATSIntegrationSettings,
  IWorkableJobOpening,
  TATSIntegrationType,
  useAddATSIntegrationMutation,
  useATSJobOpeningsQuery,
  useCreateJobOpeningATSIntegrationSettingsMutation,
  useDeleteJobOpeningATSIntegrationSettingsMutation,
  useUpdateJobOpeningATSIntegrationSettingsMutation,
  WorkableIcon,
} from '@/features/integrations';
import { useJobOpeningPermission } from '@/features/permissions';
import { useGenericPricingUpsellModalContext } from '@/features/upsell';
import { usePlanLimits } from '@/hooks/planLimits';

const validationSchema = object().shape({
  jobOpeningExternalId: mixed().when('integrationCreated', {
    is: (integrationCreated) => integrationCreated === 'true',
    then: (schema) => schema.required('Please select external job opening!'),
  }),
});

const integrationTypes = {
  workable: {
    name: 'Workable',
    icon: <WorkableIcon />,
  },
  greenhouse: {
    name: 'Greenhouse',
    icon: <GreenhouseIcon />,
  },
};

export function ATSIntegration({
  type,
  atsIntegration,
  jobOpeningATSIntegrationSettings,
}: {
  type: TATSIntegrationType;
  atsIntegration?: IATSIntegration;
  jobOpeningATSIntegrationSettings?: IJobOpeningATSIntegrationSettings;
}) {
  const { openPricingUpsell } = useGenericPricingUpsellModalContext();
  const canAccessFeature = usePlanLimits();

  const canAddATSIntegration = useJobOpeningPermission()(
    'job ats integration settings',
    'create'
  );
  const canUpdateJobOpeningATSIntegrationSettings = useJobOpeningPermission()(
    'job ats integration settings',
    'update'
  );

  const addATSIntegrationMutation = useAddATSIntegrationMutation({
    onSuccess: (result: string) => result && (window.location.href = result),
  });

  const atsJobOpeningsQuery = useATSJobOpeningsQuery(
    {
      atsIntegrationType: type,
      atsIntegrationId: atsIntegration?.id!,
    },
    { enabled: !!atsIntegration?.id }
  );

  const createIntegration = useCreateJobOpeningATSIntegrationSettingsMutation();
  const updateIntegration = useUpdateJobOpeningATSIntegrationSettingsMutation();
  const deleteIntegration = useDeleteJobOpeningATSIntegrationSettingsMutation();

  const initialJobOpeningATSIntegration = {
    integrationCreated: !!jobOpeningATSIntegrationSettings ? 'true' : 'false',
    jobOpeningExternalId:
      jobOpeningATSIntegrationSettings?.jobOpeningExternalId || null,
  };

  const handleSubmit = async ({ integrationCreated, jobOpeningExternalId }) => {
    if (integrationCreated === 'false') {
      // delete integration, aka, "don't send anything to..."
      deleteIntegration.mutateAsync(jobOpeningATSIntegrationSettings?.id!);
    } else {
      const selectedATSJobOpening = atsJobOpeningsQuery.data?.find(
        (atsJobOpening) => atsJobOpening.externalId === jobOpeningExternalId
      );
      if (!selectedATSJobOpening) return;

      if (!!jobOpeningATSIntegrationSettings?.id) {
        // change external job opening
        updateIntegration.mutateAsync({
          ...jobOpeningATSIntegrationSettings,
          jobOpeningExternalId: selectedATSJobOpening.externalId,
          jobOpeningExternalName: selectedATSJobOpening.name,
          jobOpeningExternalSupersetId: (
            selectedATSJobOpening as IWorkableJobOpening
          ).subdomain,
        });
      } else {
        // create new integration
        createIntegration.mutateAsync({
          jobOpeningExternalId: selectedATSJobOpening.externalId,
          jobOpeningExternalName: selectedATSJobOpening.name,
          jobOpeningExternalSupersetId: (
            selectedATSJobOpening as IWorkableJobOpening
          ).subdomain,
          integrationType: type,
        });
      }
    }
  };

  return (
    <IntegrationGrid>
      <Icon>{integrationTypes[type].icon}</Icon>
      <IntegrationInfo>
        <Title>{integrationTypes[type].name} integration</Title>
        <Description>
          Send candidates who apply through Toggl Hire to your{' '}
          {integrationTypes[type].name} jobs.
        </Description>
      </IntegrationInfo>
      <Action>
        {canAddATSIntegration && !atsIntegration && (
          <DSButton
            variant="secondary"
            size="xsmall"
            onClick={() => {
              canAccessFeature('ats_integrations')
                ? addATSIntegrationMutation.mutate(type)
                : openPricingUpsell('generic', 'premium');
            }}
          >
            Get started
          </DSButton>
        )}
      </Action>
      {atsIntegration && (
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialJobOpeningATSIntegration}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, submitForm, dirty }) => (
            <>
              <SettingsForm>
                <FormikRadioField
                  label="Send candidates over the threshold to"
                  name="integrationCreated"
                  value="true"
                />
                {values.integrationCreated === 'true' && (
                  <ATSJobOpeningSelector>
                    <FormikSelectField
                      name="jobOpeningExternalId"
                      placeholder="Select"
                      items={
                        atsJobOpeningsQuery.data?.map((atsJobOpening) => ({
                          id: atsJobOpening.externalId,
                          label: atsJobOpening.name,
                        })) || []
                      }
                    />
                  </ATSJobOpeningSelector>
                )}
                <FormikRadioField
                  label={`Don't send anything to ${integrationTypes[type].name}`}
                  name="integrationCreated"
                  value="false"
                  onChange={() => {
                    setFieldValue('integrationCreated', 'false');
                    setFieldValue('jobOpeningExternalId', null);
                  }}
                />
              </SettingsForm>
              <SettingsAction>
                {canUpdateJobOpeningATSIntegrationSettings && (
                  <DSButton disabled={!dirty} onClick={submitForm}>
                    Save
                  </DSButton>
                )}
              </SettingsAction>
            </>
          )}
        </Formik>
      )}
    </IntegrationGrid>
  );
}

const IntegrationGrid = styled.div`
  display: grid;
  grid-template-columns: min-content auto min-content;
  gap: 12px;
`;

const IntegrationInfo = styled.div`
  padding-top: 2px;
`;

const Icon = styled.div`
  width: 46px;
  height: 46px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 1rem;
  color: ${(props) => props.theme.typography.colorPrimary};
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 1rem;
  color: ${(props) => props.theme.typography.colorSecondary};
`;

const Action = styled.div`
  padding-top: 11px;
`;

const SettingsForm = styled(Form)`
  grid-column: 2 / 4;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SettingsAction = styled.div`
  grid-column: 3;
  grid-row: 3;
  padding-top: 8px;
`;

const ATSJobOpeningSelector = styled.div`
  max-width: 300px;
  margin-left: 24px;
`;
