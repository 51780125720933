import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSSelect } from '@hundred5/design-system';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { Icon } from '@/features/common';
import { useEmailTemplateVariablesQuery } from '@/features/email';
import { useWorkspaceId } from '@/hooks/router';

interface VariablesSelectProps {
  onVariableSelect: (value: string) => void;
}

export const VariablesSelect = ({ onVariableSelect }: VariablesSelectProps) => {
  const emailTemplateVariablesQuery = useEmailTemplateVariablesQuery();
  const variables = emailTemplateVariablesQuery.data;
  const workspaceId = useWorkspaceId();
  const amplitude = getAmplitudeClient();
  if (!variables || variables.length === 0) {
    return null;
  }

  return (
    <>
      <StyledDSSelect
        Icon={<Icon icon={regular('layer-group')} color="purple-60" />}
        placeholder="Insert variable"
        alwaysShowPlaceholder
        items={variables.map((variable) => ({
          id: variable.placeholder,
          label: variable.placeholder.replace(/([A-Z])/g, ' $1').toLowerCase(),
        }))}
        onChange={(item) => {
          if (item) {
            onVariableSelect(`[${item.id}]`);
            amplitude?.logEvent('email templates/variable click', {
              'workspace id': workspaceId,
              variable: item.label,
            });
          }
        }}
        small
      />
    </>
  );
};

const StyledDSSelect = styled(DSSelect)`
  .dropdown {
    width: 184px;
    left: 0;
  }
`;
