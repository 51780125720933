import React, { useState } from 'react';
import { DSButton, DSField, DSInput, DSModal } from '@hundred5/design-system';
import { useFormikContext } from 'formik';
import { useMedia } from 'react-use';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { useCreateJobOpeningMutation } from '@/features/job-opening';
import { IAddTestToPipelineFormValues } from '@/features/test-library';

import addNewJobIllustration from '../assets/add-new-job-illustration.svg';

import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
} from './modal';

export const AddJobModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [jobOpeningName, setJobOpeningName] = useState('');
  const isMobile = useMedia('(max-width: 768px)');
  const createJobOpeningMutation = useCreateJobOpeningMutation();
  const form = useFormikContext<IAddTestToPipelineFormValues>();
  const amplitude = getAmplitudeClient();
  const handleSave = async () => {
    const createdJobOpening = await createJobOpeningMutation.mutateAsync({
      name: jobOpeningName,
    });
    form.setFieldValue('jobOpeningId', createdJobOpening.id);
    amplitude?.logEvent('test library/new job created', {
      jobOpeningId: createdJobOpening.id,
    });
    onClose();
  };
  return (
    <DSModal
      open={open}
      contentStyle={{ width: isMobile ? '100%' : '644px' }}
      layer={2}
    >
      <ModalHeader>Add new job</ModalHeader>
      <DSModal.CloseButton fixed small onClick={() => onClose()} />

      <DSModal.Separator />
      <ModalBody>
        <DSField for="jobOpeningName" required label="job">
          <DSInput
            name="jobOpeningName"
            onChange={(event) => setJobOpeningName(event.target.value)}
          />
        </DSField>
      </ModalBody>
      {!isMobile && <img alt="" src={addNewJobIllustration} />}
      <ModalFooter>
        <ModalFooterButtons>
          <DSButton variant="secondary" onClick={onClose}>
            Cancel
          </DSButton>
          <DSButton
            disabled={!!!jobOpeningName || createJobOpeningMutation.isLoading}
            onClick={handleSave}
          >
            Save
          </DSButton>
        </ModalFooterButtons>
      </ModalFooter>
    </DSModal>
  );
};
