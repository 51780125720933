import * as React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';

import { useFreeTrial } from '@/features/billing';
import { Icon } from '@/features/common';
import { useWorkspace } from '@/features/workspace';
import { isATSPlan } from '@/hooks/planLimits';

import { useGenericPricingUpsellModalContext } from '../../hooks';

interface IGenericUpsellTooltipProps {
  title?: string;
  description?: string;
}

const ReactHint = ReactHintFactory(React);

export function GenericUpsellTooltip({
  title,
  description,
}: IGenericUpsellTooltipProps) {
  return (
    <TooltipContainer>
      <ReactHint
        events
        persist
        attribute="data-generic-upsell-tooltip"
        delay={{ show: 0, hide: 300 }}
        position="bottom"
        onRenderContent={() => {
          return <Content title={title} description={description} />;
        }}
      />
    </TooltipContainer>
  );
}

function Content({ title, description }) {
  const { isTrialAvailable } = useFreeTrial();
  const { openPricingUpsell } = useGenericPricingUpsellModalContext();
  const workspace = useWorkspace();

  return (
    <Wrapper>
      <TopContainer>
        <Triangle />
      </TopContainer>
      <Container>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <DSButton
          variant="secondary"
          size="small"
          fullWidth
          Icon={<Icon icon={regular('crown')} />}
          onClick={() =>
            !isATSPlan(workspace?.subscriptionPlan!)
              ? openPricingUpsell('changePlan', 'premium')
              : openPricingUpsell('generic')
          }
        >
          {!!isTrialAvailable
            ? 'Start a free trial'
            : !isATSPlan(workspace?.subscriptionPlan!)
              ? 'Change plan'
              : 'Upgrade'}
        </DSButton>
      </Container>
    </Wrapper>
  );
}

const TooltipContainer = styled.div`
  .react-hint {
    z-index: 1000;
  }

  .react-hint--bottom::after {
    display: none;
  }
`;

const Wrapper = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: ${(props) => props.theme.shadow.popup};
  background: ${(props) => props.theme.colors.white};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;

  border-bottom: 8px solid ${(props) => props.theme.colors.orange[90]};
  position: relative;
  left: 50%;
  top: -4px;
  transform: translateX(-25%);
`;

const TopContainer = styled.div`
  background-color: ${(props) => props.theme.colors.orange[90]};
  height: 8px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const Container = styled.div`
  padding: 16px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  height: 126px;
  width: 240px;
  border-radius: 8px;
  justify-content: space-between;
`;

const Title = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  margin-bottom: 4px;
  color: ${(props) => props.theme.colors.purple[100]};
`;

const Description = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.purple[100]};
`;
