import React from 'react';
import styled from '@emotion/styled';

import { TId } from '@/features/common';
import { useJobOpeningPageAnalytics } from '@/features/job-opening/utils/job-opening-page-analytics';
import { TScreenType, useTestQuery } from '@/features/test';

import { ResultPageSettingsForm } from './result-page-settings-form';

interface ResultPageSettingsProps {
  testId: TId;
}

export function ResultPageSettings({ testId }: ResultPageSettingsProps) {
  useJobOpeningPageAnalytics('job opening test result page');
  const testQuery = useTestQuery({ testId });

  if (!testQuery.isSuccess) {
    return null;
  }

  return (
    <ResultPageSettingsContainer>
      {testQuery.data.percentToPass ? (
        <>
          <ResultPageSettingsForm
            testId={testId}
            screenType={TScreenType.Passed}
          />
          <ResultPageSettingsForm
            testId={testId}
            screenType={TScreenType.NotPassed}
          />
        </>
      ) : (
        <ResultPageSettingsForm
          testId={testId}
          screenType={TScreenType.NoThreshold}
        />
      )}
    </ResultPageSettingsContainer>
  );
}

const ResultPageSettingsContainer = styled.div`
  display: grid;
  gap: 36px;
`;
