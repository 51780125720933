import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';
import { sortBy } from 'lodash';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { Icon } from '@/features/common';
import { useDashboardJobOpeningsQuery } from '@/features/dashboard';
import {
  useMeQuery,
  usePreferenceMutation,
  usePreferencesQuery,
} from '@/features/me';
import {
  TChecklistItem,
  useWorkspaceStatsQuery,
  WORKSPACE_STATS_QUERY_KEY,
} from '@/features/workspace';
import { useHistory, useWorkspaceId } from '@/hooks/router';
import { queryClient } from '@/utils/reactQuery';

import { useUpdateChecklistMutation } from '../../queries';

import { ChecklistItem, ProgressBar } from './';

interface ChecklistProps {
  onClose: () => void;
}

export function Checklist({ onClose }: ChecklistProps) {
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const workspaceStatsQuery = useWorkspaceStatsQuery({ workspaceId });
  const updateChecklistMutation = useUpdateChecklistMutation();
  const meQuery = useMeQuery();
  const preferencesQuery = usePreferencesQuery();
  const preferenceMutation = usePreferenceMutation();
  const [progress, setProgress] = useState<number>(20);
  const amplitude = getAmplitudeClient();
  const dashboardJobOpeningsQuery = useDashboardJobOpeningsQuery({
    workspaceId,
    filter: 'open',
  });
  const firstJobOpening = dashboardJobOpeningsQuery.isSuccess
    ? sortBy(dashboardJobOpeningsQuery.data.openings, 'id')[0]
    : undefined;

  const workspace = meQuery.isSuccess
    ? meQuery.data.workspaces?.find((workspace) => workspace.id === workspaceId)
    : undefined;

  const displayNewActivityFeed =
    workspace?.createdAt.toISOString() &&
    workspace?.createdAt.toISOString() > '2024-09-23T11:40:14.210Z';

  const checkListItems = useMemo(() => {
    const findCheckListItem = (checkListItem: TChecklistItem) => {
      if (workspaceStatsQuery.isSuccess) {
        return (
          !!workspaceStatsQuery.data.checklist?.find(
            (item) => item === checkListItem
          ) || false
        );
      }
      return false;
    };
    return [
      {
        name: 'Create your first job',
        done: findCheckListItem('CreateFirstJob'),
        clickable: !findCheckListItem('CreateFirstJob'),
        onClick: () => {
          if (!findCheckListItem('CreateFirstJob')) {
            history.push(`/admin/${workspaceId}/test-library`);
            amplitude?.logEvent('dashboard/checklist', {
              type: 'CreateFirstJob',
            });
          }
        },
      },
      {
        name: 'Add job description',
        done: findCheckListItem('EditJobDescription'),
        clickable:
          !findCheckListItem('EditJobDescription') && !!firstJobOpening,
        onClick: () => {
          if (!findCheckListItem('EditJobDescription') && !!firstJobOpening) {
            history.push(
              `/admin/${workspaceId}/openings/${firstJobOpening?.id}/job-description`
            );
            amplitude?.logEvent('dashboard/checklist', {
              type: 'EditJobDescription',
            });
          }
        },
      },
      {
        name: 'Add a test',
        done: findCheckListItem('CreateTest'),
        clickable: !findCheckListItem('CreateTest') && !!firstJobOpening,
        onClick: () => {
          if (!findCheckListItem('CreateTest') && !!firstJobOpening) {
            history.push(`/admin/${workspaceId}/test-library`);
            amplitude?.logEvent('dashboard/checklist', { type: 'CreateTest' });
          }
        },
      },
      {
        name: 'Invite team members',
        done: findCheckListItem('InviteTeamMember'),
        clickable: !findCheckListItem('InviteTeamMember'),
        onClick: () => {
          if (!findCheckListItem('InviteTeamMember')) {
            history.push(`/admin/${workspaceId}/settings/team`);
            amplitude?.logEvent('dashboard/checklist', {
              type: 'InviteTeamMember',
            });
          }
        },
      },
      {
        name: 'Preview & share',
        done: findCheckListItem('PreviewJob'),
        clickable: !findCheckListItem('PreviewJob') && !!firstJobOpening,
        onClick: () => {
          if (!findCheckListItem('PreviewJob') && !!firstJobOpening) {
            window.open(`/c/${firstJobOpening?.slug}/overview`, '_blank');
            updateChecklistMutation.mutate(
              {
                workspaceId,
                checklistItem: 'PreviewJob',
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries(WORKSPACE_STATS_QUERY_KEY);
                },
              }
            );
            amplitude?.logEvent('dashboard/checklist', { type: 'PreviewJob' });
          }
        },
      },
    ];
  }, [
    firstJobOpening,
    workspaceStatsQuery.isSuccess,
    workspaceStatsQuery.data,
    history,
    workspaceId,
    amplitude,
    updateChecklistMutation,
  ]);

  const closedActivityFeedChecklistPreference = preferencesQuery.isSuccess
    ? preferencesQuery.data?.closedActivityFeedChecklist
    : false;

  const isChecklistFinished = checkListItems.every((item) => item.done);

  const onCloseClick = () => {
    if (isChecklistFinished) {
      preferenceMutation.mutate({
        name: 'closedActivityFeedChecklist',
        value: true,
      });
    } else {
      onClose();
    }
  };

  useEffect(() => {
    setProgress(
      (checkListItems.filter((item) => item.done).length * 100) /
        checkListItems.length
    );
  }, [checkListItems]);

  if (
    !workspace ||
    closedActivityFeedChecklistPreference ||
    !displayNewActivityFeed
  ) {
    return null;
  }

  return (
    <ChecklistContainer>
      <ChecklistHeader>
        <ChecklistTitle>
          {isChecklistFinished
            ? `All done! Ready to get the most out of Toggl Hire?`
            : `Let's set up your job`}
        </ChecklistTitle>
        <ChecklistCloseButton onClick={onCloseClick}>
          <Icon icon={regular('xmark')} color="peach-70" />
        </ChecklistCloseButton>
      </ChecklistHeader>
      {isChecklistFinished ? (
        <ChecklistButton
          size="small"
          onClick={() => {
            amplitude?.logEvent(
              'dashboard/checklist-training-session-button-click'
            );
            window.open(
              'https://calendly.com/toggl-hire-team/toggl-hire-product-training',
              '_blank'
            );
          }}
          variant="secondary"
          fullWidth
        >
          Book a free training session
        </ChecklistButton>
      ) : (
        <>
          <ProgressBar value={progress} />
          {checkListItems.map((item) => (
            <ChecklistItem
              key={item.name}
              name={item.name}
              done={item.done}
              clickable={item.clickable && !item.done}
              onClick={item.onClick}
            />
          ))}
        </>
      )}
    </ChecklistContainer>
  );
}

const ChecklistContainer = styled.div`
  background: ${({ theme }) => theme.colors.purple[90]};
  border-radius: 8px;
  box-shadow: 0 1px 40px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  margin: 14px 16px;
  padding: 12px;
  transition: background-color 0.2s ease-in-out;
`;

const ChecklistHeader = styled.div`
  display: flex;
  gap: 12px;
  line-height: 18px;
`;

const ChecklistCloseButton = styled.div`
  padding: 0 2px;
  margin-left: auto;
  cursor: pointer;
  font-size: 12px;
`;

const ChecklistTitle = styled.div`
  color: ${({ theme }) => theme.colors.peach[70]};
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 4px;
`;

const ChecklistButton = styled(DSButton)`
  font-size: 12px;
  height: 24px;
  margin-top: 12px;
`;
