import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { FormikInputField } from '@/features/common';
import { useJobOpeningId } from '@/hooks/router';

interface CreatePipelineStageModalProps {
  onClose: () => void;
  onSave: (name: string) => void;
  open: boolean;
}

const validationSchema = yup.object().shape({
  stageName: yup.string().required('Name is required'),
});

export function CreatePipelineStageModal({
  onClose,
  onSave,
  open,
}: CreatePipelineStageModalProps) {
  const jobOpeningId = useJobOpeningId();
  const amplitude = getAmplitudeClient();
  return (
    <Formik<{ stageName: string }>
      initialValues={{
        stageName: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSave(values.stageName);
        amplitude?.logEvent('pipeline/step renamed', {
          'job opening id': jobOpeningId,
          'pipeline step': values.stageName,
        });
      }}
    >
      <DSModal open={open} onClose={onClose} contentStyle={{ maxWidth: 442 }}>
        <DSModal.CloseButton small fixed onClick={onClose} />
        <Header>Add stage</Header>
        <DSModal.Separator />
        <Form>
          <Content>
            <FormikInputField
              label="Name"
              name="stageName"
              placeholder="Stage name"
              required
            />
          </Content>
          <Footer>
            <DSButton type="submit">Add stage</DSButton>
          </Footer>
        </Form>
      </DSModal>
    </Formik>
  );
}

const Header = styled.h2`
  margin-bottom: 16px;
  margin-top: 0;
`;

const Content = styled.div`
  margin-bottom: 24px;
  margin-top: 16px;
`;

const Footer = styled(DSModal.Footer)`
  gap: 8px;
`;
