import * as amplitude from '@amplitude/analytics-browser';
import { noop } from 'lodash';

import { userHasConsentedToMarketing } from '@/features/gdpr/utils/permissions';

const defaultClient = { logEvent: noop, identify: noop };

export function getAmplitudeClient() {
  if (userHasConsentedToMarketing()) {
    return amplitude;
  }

  return defaultClient;
}
