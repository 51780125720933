import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { format } from 'date-fns/format';
import { capitalize } from 'lodash';

import { useFreeTrial } from '@/features/billing';
import { useHistory, useWorkspaceIdOrNull } from '@/hooks/router';

export const TopSuccessUpsellBanner = () => {
  const history = useHistory();
  const workspaceId = useWorkspaceIdOrNull();
  const { currentTrial } = useFreeTrial();

  const onUpgradeClick = () => {
    history.push(`/admin/${workspaceId}/settings/billing`);
    amplitude?.logEvent('upsell/top banner/upgrade', {
      'workspace id': workspaceId,
    });
  };

  if (!currentTrial) return null;

  return (
    <Container>
      <span>
        You are on the {capitalize(currentTrial.subscriptionPlanType)} plan
        trial. Your trial will end on{' '}
        <b>{format(currentTrial.endsAt, 'dd/MM/yyyy')}</b>
      </span>
      <DSButton variant="tertiary" size="xsmall" onClick={onUpgradeClick}>
        Upgrade
      </DSButton>
    </Container>
  );
};

const Container = styled.div`
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.purple[10]};
  font-size: 14px;
  text-align: center;
  gap: 12px;
`;
