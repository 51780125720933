import { makeFetchTransport } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { History } from 'history';

import { userHasConsentedToStatistics } from '@/features/gdpr/utils/permissions';

/**
 * This custom fetch is used so that events are not sent to sentry if the user
 * has not consented to tracking
 */
const customFetch = (
  input: RequestInfo | URL,
  init?: RequestInit | undefined
): Promise<Response> => {
  if (!userHasConsentedToStatistics()) {
    return Promise.resolve(new Response(null));
  }

  return window.fetch(input, init);
};

export default function configureSentry(history: History) {
  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
      integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({ history }),
        Sentry.replayIntegration(),
      ],
      replaysOnErrorSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      tracePropagationTargets: [import.meta.env.VITE_API_URL],
      tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE
        ? parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE)
        : 1.0,
      transport: (options) => makeFetchTransport(options, customFetch),
    });
  }
}
