import React from 'react';

import { TQuestionDifficulty } from '@/features/questions';

export interface IQuestionDifficultyOption {
  id: TQuestionDifficulty;
  label: React.ReactNode;
  labelText?: string;
  disabled?: boolean;
}

export const QUESTION_DIFFICULTY_OPTIONS: IQuestionDifficultyOption[] = [
  {
    id: 'standard',
    label: 'Standard',
  },
  {
    id: 'hard',
    label: <div>Advanced</div>,
  },
];
