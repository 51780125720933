import { useCallback, useMemo } from 'react';

import { BillingPlan, billingPlansDescriptions } from '@/features/billing';
import { IDashboardJobOpening } from '@/features/dashboard';
import { useJobOpeningByIdQuery } from '@/features/job-opening';
import { useMeQuery } from '@/features/me';
import {
  ITrial,
  IWorkspace,
  useWorkspace,
  useWorkspaceQuery,
  useWorkspaceStatsQuery,
} from '@/features/workspace';
import {
  useJobOpeningIdOrNull,
  useWorkspaceId,
  useWorkspaceIdOrNull,
} from '@/hooks/router';

export type PlanFeature =
  | 'api_access'
  | 'export_candidates'
  | 'candidate_attachments'
  | 'candidate_emails'
  | 'ats_integrations'
  | 'slack_integration'
  | 'zapier_integration'
  | 'job_opening_analytics'
  | 'disable_question_rating'
  | 'candidate_redirect_url'
  | 'disable_score_chart'
  | 'suspicious_activities'
  | 'answer_paste'
  | 'create_custom_test'
  | 'customise_test'
  | 'homework_test'
  | 'async_interview_test'
  | 'candidate_history'
  | 'bulk_actions'
  | 'add_candidate'
  | 'pause_job_opening'
  | 'pipeline_automation'
  | 'job_opening_user_rights'
  | 'edit_job_description'
  | 'edit_test_landing'
  | 'candidate_answer_ai_features'
  | 'gmail_integration';

export type PlanLimitedFeature =
  | 'job_openings'
  | 'unlockable_candidates'
  | 'tests_in_job_opening';

export type PlanLevel =
  | 'free'
  | '2024-09-free-ats' // ATS new plan
  | 'premium-2021'
  | 'premium-exp-2023'
  | 'premium'
  | 'premium-12m-exp-2023'
  | 'premium-1m-2021'
  | 'premium-1m-exp-2023'
  | 'premium-v2-2023'
  | 'premium-60c-2023'
  | 'premium-45c-2024'
  | 'premium-legacy'
  | 'business-legacy'
  | '2024-09-premium-1m-ats' // ATS new plan
  | '2024-09-premium-12m-ats' // ATS new plan
  | 'demo'
  | 'starter'
  | 'basic'
  | 'basic-12m-2024'
  | '2024-09-starter-1m-ats' // ATS new plan
  | '2024-09-starter-12m-ats' // ATS new plan
  | 'business'
  | 'business-exp-2023'
  | 'business-v2-2023'
  | 'business-12m-v2-2023'
  | 'unlimited';

export type PlanName =
  | 'free' // ATS plan
  | 'starter' // ATS plan
  | 'premium' // ATS plan
  | 'basic'
  | 'business'
  | 'unlimited'
  | 'demo';

export type NextPlanName = 'starter' | 'premium' | 'business' | 'enterprise'; // to be removed:  'business' | 'enterprise'

/**
 * Include all the free plan of ats
 */
export const freePlans: PlanLevel[] = [
  'free',
  '2024-09-free-ats', // ATS new plan
];

/**
 * Include all the basic and new starter plan of ats
 */
export const starterPlans: PlanLevel[] = [
  'basic',
  'starter',
  '2024-09-starter-1m-ats',
  '2024-09-starter-12m-ats',
];

export const premiumPlans: PlanLevel[] = [
  'premium',
  'premium-2021',
  'premium-exp-2023',
  'premium-v2-2023',
  'premium-60c-2023',
  'premium-45c-2024',
  'business',
  'business-exp-2023',
  'business-v2-2023',
  '2024-09-premium-1m-ats',
  '2024-09-premium-12m-ats',
  'premium-legacy',
  'business-legacy',
  'unlimited',
  'demo',
];

export const premiumPlansWithoutLegacyPremium: PlanLevel[] = [
  'premium',
  'business',
  'business-exp-2023',
  'business-v2-2023',
  'business-12m-v2-2023',
  'business-legacy',
  '2024-09-premium-1m-ats',
  '2024-09-premium-12m-ats',
  'unlimited',
  'demo',
];

export const premiumPlansWithoutLegacyPremiumBusiness: PlanLevel[] = [
  'premium',
  'premium-2021',
  'premium-exp-2023',
  'premium-v2-2023',
  'premium-60c-2023',
  'premium-45c-2024',
  'business',
  'business-exp-2023',
  'business-v2-2023',
  'business-12m-v2-2023',
  'business-legacy',
  '2024-09-premium-1m-ats',
  '2024-09-premium-12m-ats',
  'unlimited',
  'demo',
];

export const paidPlans: PlanLevel[] = [
  'basic',
  'premium',
  'premium-2021',
  'premium-exp-2023',
  'premium-v2-2023',
  'premium-60c-2023',
  'premium-45c-2024',
  '2024-09-starter-1m-ats',
  '2024-09-starter-12m-ats',
  '2024-09-premium-1m-ats',
  '2024-09-premium-12m-ats',
  'business',
  'business-exp-2023',
  'business-v2-2023',
  'unlimited',
];
// we will have 3 categories of plans 'free' 'starter' 'premium'
const requiredPlans: Record<PlanFeature, PlanLevel[]> = {
  api_access: premiumPlans,
  export_candidates: premiumPlans,
  candidate_attachments: premiumPlans,
  candidate_emails: [...starterPlans, ...premiumPlans],
  ats_integrations: premiumPlans,
  slack_integration: premiumPlans,
  zapier_integration: premiumPlans,
  job_opening_analytics: [...starterPlans, ...premiumPlans],
  disable_question_rating: premiumPlans,
  candidate_redirect_url: premiumPlansWithoutLegacyPremium,
  disable_score_chart: premiumPlans,
  suspicious_activities: [...starterPlans, ...premiumPlans],
  answer_paste: premiumPlans,
  create_custom_test: [...starterPlans, ...premiumPlans],
  customise_test: [...starterPlans, ...premiumPlans],
  homework_test: premiumPlans,
  async_interview_test: [...starterPlans, ...premiumPlans],
  candidate_history: [...starterPlans, ...premiumPlans],
  add_candidate: premiumPlans,
  pipeline_automation: [...starterPlans, ...premiumPlans],
  bulk_actions: [...starterPlans, ...premiumPlansWithoutLegacyPremium],
  pause_job_opening: [...freePlans, ...starterPlans, ...premiumPlans],
  job_opening_user_rights: premiumPlansWithoutLegacyPremium,
  edit_job_description: [...starterPlans, ...premiumPlans],
  edit_test_landing: [...starterPlans, ...premiumPlans],
  candidate_answer_ai_features: [...starterPlans, ...premiumPlans],
  gmail_integration: premiumPlans,
};

export const useGetPlanRequired = (
  feature: PlanFeature,
  planPeriod: 'monthly' | 'annual'
): BillingPlan | undefined => {
  return useMemo(
    () =>
      [
        ...new Set(
          requiredPlans[feature]
            .filter((planLevel) =>
              billingPlansDescriptions.plans
                .map((plan) => plan.planLevel)
                .includes(planLevel)
            )
            .sort(
              (planLevelA, planLevelB) =>
                getPlanStrenght(getPlanName(planLevelA)) -
                getPlanStrenght(getPlanName(planLevelB))
            )
            .map(
              (planLevel) =>
                billingPlansDescriptions.plans.find(
                  (plan) => plan.planLevel === planLevel
                )?.planCode[planPeriod]
            )
        ),
      ][0],
    [feature, planPeriod]
  );
};

// It is used to groupby all the old and new plans into new categories.
// We will have only three categories FREE | STARTER | PREMIUM,
// latest has been included legacy plans premium & business
export const planLevelsType: { [K in BillingPlan]: PlanLevel } = {
  free: 'free',
  '2024-09-free-ats': 'free', // ATS plan

  basic: 'starter',
  starter: 'starter',
  'basic-12m-2024': 'starter',
  '2024-09-starter-1m-ats': 'starter', // ATS plan
  '2024-09-starter-12m-ats': 'starter', // ATS plan

  premium: 'premium',
  '2024-09-premium-1m-ats': 'premium', // ATS plan
  '2024-09-premium-12m-ats': 'premium', // ATS plan

  'premium-1m-2021': 'premium-legacy',
  'premium-12m-2021': 'premium-legacy',
  'premium-1m-exp-2023': 'premium-legacy',
  'premium-12m-exp-2023': 'premium-legacy',
  'premium-1m-v2-2023': 'premium-legacy',
  'premium-12m-60c-2023': 'premium-legacy',
  'premium-12m-45c-2024': 'premium-legacy',

  'business-1m-2021': 'business-legacy',
  'business-12m-2021': 'business-legacy',
  'business-12m-exp-2023': 'business-legacy',
  'business-1m-v2-2023': 'business-legacy',
  'business-12m-v2-2023': 'business-legacy',

  'unlimited-1m-2021': 'premium',
  'unlimited-12m-2021': 'premium',

  'demo-workspace': 'demo',
};

const planLimits: Record<
  PlanLimitedFeature,
  Partial<Record<PlanLevel, number>>
> = {
  job_openings: {
    free: 1,
    starter: 3,
  },
  unlockable_candidates: {
    free: 5,
    basic: 20,
    'premium-exp-2023': 2000,
    'premium-60c-2023': 60,
    'premium-v2-2023': 300,
    'premium-45c-2024': 45,
  },
  tests_in_job_opening: {
    free: 1,
  },
};

// Trial return as subscribePlan = 'basic' | 'starter' | 'premium'
export const getCurrentSubscription = (
  workspace: IWorkspace
): PlanLevel | BillingPlan => {
  const trialPlan =
    !!workspace && workspace?.trialState === 'active'
      ? workspace.trials?.sort((a: ITrial, b: ITrial) => {
          const dateA = a.startedAt
            ? new Date(a.startedAt).getTime()
            : Infinity;
          const dateB = b.startedAt
            ? new Date(b.startedAt).getTime()
            : Infinity;

          return dateB - dateA;
        })?.[0]?.subscriptionPlanType
      : undefined;

  return trialPlan ?? workspace.subscriptionPlan;
};

export const getNextPlanLevel = (
  currentPlanName: BillingPlan
): NextPlanName | undefined | null => {
  switch (planLevelsType[currentPlanName]) {
    case 'free':
      return 'starter';
    case 'starter':
      return 'premium';
    case 'premium':
    case 'demo':
      return undefined;
    default:
      return null;
  }
};

export const getPlanLimit = (
  plan: BillingPlan | PlanLevel,
  feature: PlanLimitedFeature
): number => {
  const planLevel = planLevelsType[plan];

  return planLimits[feature][planLevel] || Number.MAX_VALUE;
};

export const usePlanLimits = () => {
  const workspaceId = useWorkspaceIdOrNull();
  const workspaceQuery = useWorkspaceQuery({
    workspaceId: workspaceId ?? undefined,
  });

  return useCallback(
    (feature: PlanFeature): boolean | null => {
      if (!workspaceQuery.isSuccess) {
        return null;
      }

      return requiredPlans[feature].includes(
        planLevelsType[getCurrentSubscription(workspaceQuery.data!)]
      );
    },
    [workspaceQuery.isSuccess, workspaceQuery.data]
  );
};

/** Is ATS plan if matches format "year-month-[plan name and other details]" */
export const isATSPlan = (plan: BillingPlan | PlanLevel): boolean =>
  /^[0-9]{4}-[0-9]{2}-.+$/.test(plan);

export const getPlanName = (plan: BillingPlan | PlanLevel): PlanName => {
  return isATSPlan(plan)
    ? (plan.split('-')[2] as PlanName)
    : (plan.split('-')[0] as PlanName);
};

export const getPlanStrenght = (planName: PlanName): number => {
  switch (planName) {
    case 'free':
      return 0;
    case 'basic':
    case 'starter':
      return 1;
    case 'premium':
      return 2;
    case 'business':
      return 3;
    case 'unlimited':
      return 4;
    case 'demo':
      return 4;
  }
};

export const getPlanPeriod = (plan: BillingPlan): 'monthly' | 'annual' => {
  if (/1-month|1m/.test(plan)) {
    return 'monthly';
  } else {
    return 'annual';
  }
};

export const isPaidPlan = (plan: BillingPlan): boolean => {
  return paidPlans.includes(planLevelsType[plan]);
};

export const useIsPaidPlan = () => {
  const workspaceId = useWorkspaceId();
  const workspaceQuery = useWorkspaceQuery({ workspaceId });

  return useMemo((): boolean | null => {
    if (!workspaceQuery.isSuccess) {
      return null;
    }

    return isPaidPlan(workspaceQuery.data?.subscriptionPlan!);
  }, [workspaceQuery.isSuccess, workspaceQuery.data?.subscriptionPlan]);
};

// Job opening limits
export const useJobOpeningsLimit = (): boolean | null => {
  const workspaceId = useWorkspaceId();
  const meQuery = useMeQuery();
  const workspaceStatsQuery = useWorkspaceStatsQuery({ workspaceId });

  return useMemo(() => {
    if (!meQuery.isSuccess || !workspaceStatsQuery.isSuccess) {
      return null;
    }

    // cased to non-null, users are not able to see workspaces they are not a part of
    const workspace = meQuery.data.workspaces?.find(
      (workspace) => workspace.id === workspaceId
    )!;

    return (
      workspaceStatsQuery.data.activeJobOpenings <
      getPlanLimit(getCurrentSubscription(workspace), 'job_openings')
    );
  }, [workspaceId, meQuery, workspaceStatsQuery]);
};

// Locked candidates after 30 days
export const useLockedCandidates = (): boolean => {
  // check the date when a workspace is created and return true if it is older than 30 days
  const workspace = useWorkspace();

  // this is going to be deleted in case we get the data from useWorkspaceStatsQuery TBD
  const isCandidateValid = new Date(workspace?.createdAt!);
  isCandidateValid.setDate(isCandidateValid.getDate() + 30);

  return (
    isCandidateValid <= new Date() &&
    planLevelsType[workspace?.subscriptionPlan!] === 'free'
  );
};

// Limitation of test per jobOpening
export const useTestJobOpeningLimit = () => {
  const jobOpeningId = useJobOpeningIdOrNull();
  const workspace = useWorkspace();
  const { data: jobOpening } = useJobOpeningByIdQuery({
    jobOpeningId: jobOpeningId ?? undefined,
  });

  return useCallback(
    (dashboardJobOpening?: IDashboardJobOpening) => {
      if (!!dashboardJobOpening?.pipelineStats?.length) {
        return (
          dashboardJobOpening.pipelineStats.filter((stats) => !!stats.testId)
            .length <
          getPlanLimit(
            getCurrentSubscription(workspace!),
            'tests_in_job_opening'
          )
        );
      }

      if (!!jobOpening) {
        return (
          jobOpening.pipelineStages.filter((stage) => !!stage.testId).length <
          getPlanLimit(
            getCurrentSubscription(workspace!),
            'tests_in_job_opening'
          )
        );
      }

      return true;
    },
    [jobOpening, workspace]
  );
};
