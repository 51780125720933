import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { useRouteMatch } from 'react-router-dom';

import {
  DemoBadge,
  getTestTooltipContent,
  NavigationMenu,
  PageHeader,
  TestTypeIcon,
} from '@/features/common';
import {
  JobOpeningClosedTag,
  JobOpeningPausedTag,
} from '@/features/job-opening';
import { getJobOpeningUrlForCandidate } from '@/features/job-opening/utils/job-opening-url';
import { redirectToTryTheTestYourself, useTestPage } from '@/features/test';
import { useLocation, useWorkspaceId } from '@/hooks/router';

import { ContextMenu } from './context-menu';

export const TestPageHeader = () => {
  const location = useLocation();
  const workspaceId = useWorkspaceId();
  const match = useRouteMatch();

  const { jobOpening, test } = useTestPage();

  // Return navigation
  const searchParams: URLSearchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const returnUrl: string = searchParams.get('returnUrl') ?? 'pipeline'; // if there is no returnUrl, go back to pipeline
  const returnName: string = searchParams.get('returnName') ?? 'Pipeline';

  const tabs = [
    { key: 'questions', label: 'Test questions' },
    ...(test?.type === 'video' ? [{ key: 'intro', label: 'Intro' }] : []),
    { key: 'landing-page', label: 'Landing page' },
    { key: 'results-page', label: 'Test results page' },
    { key: 'settings', label: 'Test settings' },
  ];

  const handleTestPreview = () => {
    const url = getJobOpeningUrlForCandidate({
      jobOpeningSlug: jobOpening.slug,
      hashedTestId: test.hashedTestId,
    });

    window.open(url);
  };

  return (
    <PageHeader
      title={
        <>
          {jobOpening.name}
          <JobOpeningPausedTag pausedAt={jobOpening?.pausedAt} />
          <JobOpeningClosedTag
            closedAt={jobOpening?.closedAt}
            tooltipPosition="bottom"
          />
          {jobOpening.isDemo && <DemoBadge />}
        </>
      }
      subtitle={
        <>
          <TestTypeIcon
            testType={test.type}
            tooltip={getTestTooltipContent(test)}
          />
          {test.name}
        </>
      }
      backButton={{
        to: `/openings/${jobOpening.id}/${returnUrl}`,
        tooltip: `Go back to ${returnName}`,
      }}
      actions={
        <>
          <ButtonContainer>
            <DSButton
              variant="secondary"
              type="button"
              onClick={() => {
                redirectToTryTheTestYourself(jobOpening, workspaceId, test);
              }}
              disabled={!test.questionCount}
              data-rh={
                !test.questionCount
                  ? 'Please add at least one question to take the test.'
                  : null
              }
              data-rh-at="left"
            >
              Try the test yourself
            </DSButton>
            <DSButton
              variant="primary"
              type="button"
              onClick={handleTestPreview}
            >
              Preview test
            </DSButton>
          </ButtonContainer>
          <ContextMenu testId={test.id} />
        </>
      }
      sticky
    >
      <NavigationMenu>
        {tabs.map(({ key, label }) => (
          <NavigationMenu.Item
            key={key}
            label={label}
            to={`${match.url}/${key}?${searchParams.toString()}`}
          />
        ))}
      </NavigationMenu>
    </PageHeader>
  );
};

const ButtonContainer = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
