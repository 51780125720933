import React from 'react';
import { DSTestLandingPage } from '@hundred5/design-system';

import { GenericUpsellTooltip } from '@/features/upsell';
import { useImageUpload } from '@/hooks/images';
import { usePlanLimits } from '@/hooks/planLimits';
import { ImagePurpose } from '@/types/image';

const TITLE_IMAGE_TYPE: ImagePurpose = 'opening_background_image';

interface TitleImageProps {
  url?: string;
  position?: { x: number; y: number };
  editable?: boolean;
  onUpdate: (url?: string) => void;
  onReposition: (position: { x: number; y: number }) => void;
}

export function TitleImage({
  url,
  position,
  editable,
  onUpdate,
  onReposition,
}: TitleImageProps) {
  const { uploadImage, uploadingImage } = useImageUpload();
  const canAccessFeature = usePlanLimits();
  const canEditTestLandingPage = canAccessFeature('edit_test_landing');

  return (
    <>
      <DSTestLandingPage.TitleImage
        embedded
        url={url}
        position={position}
        editable={editable}
        loading={uploadingImage}
        onUpload={(file) => {
          uploadImage(file, TITLE_IMAGE_TYPE, false).then(([, url]) => {
            url && onUpdate(url);
            return url;
          });
        }}
        onDelete={() => {
          onUpdate('');
        }}
        onReposition={(position) => {
          onReposition(position);
        }}
        readonlyAttribute={
          !canEditTestLandingPage ? 'data-generic-upsell-tooltip' : undefined
        }
        readonly={!canEditTestLandingPage}
      />
      <GenericUpsellTooltip
        title="Customize your test landing page"
        description="Upload an image to make your company stand out"
      />
    </>
  );
}
